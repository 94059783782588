let light = {
			my_color:'#1e73be',
			shop_color:'#fa436a',
			font_color_white:'#ffffff',
			font_color_black:'#000000',
			font_color_dark:'#111111',
			font_color_base:'#333333',
			font_color_light:'#999999',
			font_color_disabled:'#cccccc',
			font_color_link:'#0569b8',
			font_color_vip:'#ff5722',
			bg_color:'#ffffff',
			bg_color_light:'#f8f8f8',
			bg_color_dark:'#e8e8e8',
			bg_color_black:'#000000',
			bg_color_mask:'rgba(0, 0, 0,0.8)',
			border_color_dark: '#dcdef6',
			border_color_base: '#e4e7ed',
			border_color_light: '#eeebf5',
			white:'white',
			black:'black'
};
let dark = {
			my_color:'#1e73be',
			shop_color:'#fa436a',
			font_color_white:'#000000',
			font_color_black:'#ffffff',
			font_color_dark:'#f5f5f5',
			font_color_base:'#999999',
			font_color_light:'#666666',
			font_color_disabled:'#333333',
			font_color_link:'#0569b8',
			font_color_vip:'#ff5722',
			bg_color:'#000000',
			bg_color_light:'#333333',
			bg_color_dark:'#666666',
			bg_color_black:'#ffffff',
			bg_color_mask:'rgba(255, 255, 255,0.8)',
			border_color_dark: '#232109',
			border_color_base: '#1b1812',
			border_color_light: '#11140a',
			white:'black',
			black:'white'
};
let small = {
			font_sm:'22rpx',
			font_base:'26rpx',
			font_lg:'30rpx',
			font_big:'34rpx',
			font_hg:'44rpx',			
			font_icon:'36rpx',
	};
let normal = {
			font_sm:'26rpx',
			font_base:'30rpx',
			font_lg:'34rpx',
			font_big:'38rpx',
			font_hg:'48rpx',
			font_icon:'40rpx',
	};	
let large = {
			font_sm:'30rpx',
			font_base:'34rpx',
			font_lg:'38rpx',
			font_big:'42rpx',
			font_hg:'52rpx',
			font_icon:'44rpx',
	};	
let big = {
			font_sm:'34rpx',
			font_base:'38rpx',
			font_lg:'42rpx',
			font_big:'46rpx',
			font_hg:'56rpx',
			font_icon:'48rpx',
	};	
		
let huge = {
			font_sm:'38rpx',
			font_base:'42rpx',
			font_lg:'46rpx',
			font_big:'50rpx',
			font_hg:'60rpx',
			font_icon:'52rpx',
	};		
export {light,dark,small,normal,large,big,huge}