
<script setup>
	import {
		onLaunch,
		onShow,
		onHide
	} from '@dcloudio/uni-app'
	import API from '@/api/api.js'
	import ws from '@/utils/websocket.js' 
    import store from "./store"
	
	onLaunch(() => {
		// #ifdef APP-PLUS
		checkUpdate()
		// #endif
		console.log('App Launch!')
			let userInfo = uni.getStorageSync('user')
			// #ifdef MP
			if(userInfo){
				ws.init()
			}
			// #endif
		// #ifndef MP
			uni.onTabBarMidButtonTap(()=>{
				// let pages = getCurrentPages();
				// let currentPage = pages[pages.length-1];
				// console.log(currentPage)
				// if(currentPage.$vm.$refs.map){ 
				// 	uni.switchTab({
				// 		url: '/pages/index/index'
				// 	});
				// }
				let pages = getCurrentPages();
				let currentPage = pages[pages.length-1];
				console.log(currentPage.$vm.$refs.page.publishShow)
				if(currentPage.$vm.$refs.page.publishShow){
					currentPage.$vm.$refs.page.hidePublish();
				}else{
					currentPage.$vm.$refs.page.showPublish();
				}
				

				currentPage.$vm.showVideo = false;
			
			})
			 // 监听系统主题变化
			 
			uni.onThemeChange(checkDarkMode);
			// #endif
			console.log('App Launch'); 
			//处理推送信息
			// #ifdef APP-PLUS
				
					plus.push.addEventListener('click', function(msg) {
							console.log(msg)
							// 分析msg.payload处理业务逻辑 
							// let message = JSON.parse(msg)
							let data = msg.payload
							if(data.url){
								console.log(data.url)
								setTimeout(()=>{
								uni.navigateTo({
									url: data.url
								})	
								},1000)							
							}

							// console.log("消息弹框被点击", msg.payload.data);
					}, false);
				
						
			//#endif
			
			checkDarkMode();
	})
	onShow(() => {

		console.log('App Show!')
		store.commit('changeAppShow', true);
		// #ifndef MP
		setTimeout(()=>{
			if(uni.getStorageSync('user')&&!ws.isOpen()){
				ws.init()
			}	
		},1000)
		//#endif
		
		checkDarkMode();
	})
	onHide(() => {
		console.log('App Hide!')
		store.commit('changeAppShow', false);
		
		console.log('App Hide')
	})
	const checkDarkMode = (e={})=> {
		 
		 let follow = uni.getStorageSync('follow')!=null ? uni.getStorageSync('follow') : true;
		if(follow){
			let theme =''
			// 获取当前系统主题
			// #ifdef MP
			theme =  e.theme ? e.theme : (uni.getSystemInfoSync().hostTheme ? uni.getSystemInfoSync().hostTheme : 'light')					
			// #endif
			// #ifndef MP
			theme =  e.theme ? e.theme : (uni.getSystemInfoSync().osTheme ? uni.getSystemInfoSync().osTheme : 'light')					
			// #endif
			console.log(theme)
			uni.setStorageSync('theme', theme);
			store.commit('changeTheme', theme);
		}
	}
	// #ifdef APP-PLUS
	const checkUpdate = ()=> {
		// 获取本地应用资源版本号
			let data = {
					appid: plus.runtime.appid,
					platform: uni.getSystemInfoSync().platform, //android或者ios
					versioncode: plus.runtime.versionCode // 打包时manifest设置的版本号 
				}
				API.getEdition(data).then(res=>{
					if(res.code){
						if (Number(res.data.edition_number) > Number(plus.runtime.versionCode) && res.data.edition_issue == 1) {
							if (res.data.package_type == 1 && res.data.edition_silence == 1) {
								silenceUpdate(res.data.edition_url)//静默更新
							} else {
								uni.navigateTo({
									url: '/uni_modules/rt-uni-update/components/rt-uni-update/rt-uni-update?obj='+JSON.stringify(res.data)
								});
							}
						}					
					}else {
						console.log(res.msg)
					}						
				})
	}
	// #endif
</script>


<style lang="scss">
	
	/*每个页面公共css */
/* #ifndef APP-PLUS-NVUE */
/* #ifdef H5 */
@font-face {
  font-family: "weather-icon"; 
  src:url('~@/static/h5/weather/weather.ttf') format('truetype');
}
/* #endif */

	@import "static/css/font.css";
	uni-modal{
		z-index: 19999 !important;
	}
	.seast-icon{
		  font-size: var(--font_icon);
	}
	/*渔币图标*/
	.seast-jinbi{
	    color: #fe7f3e;
	}
	
	/*经验图标*/
	.seast-jingyan {
	    color: #41a3ff;
	    font-size: var(--font_icon);
	}
	
	.seast-mark {
		font-size: var(--font_sm);
		color: #fff;
		margin-left: 15rpx;
		padding: 0rpx 15rpx;
		border-radius: 30rpx;
		height: 34rpx;
		line-height: 34rpx;
		white-space: nowrap;
	}
	
	.seast-verify {
		position: absolute;
		bottom: -7.5rpx;
		right: -3.75rpx;
		width: 32rpx;
		height: 32rpx;
		z-index: 99;
	
		background-size: 32rpx;
		background-repeat: no-repeat;
	}
	.seast-verify-1 {
							background-image: url("https://www.lindiaoren.com/app_images/verify/1.png");
						}
	
						.seast-verify-2 {
							background-image: url("https://www.lindiaoren.com/app_images/verify/2.png");
						}
	
						.seast-verify-3 {
							background-image: url("https://www.lindiaoren.com/app_images/verify/3.png");
							/*女神*/
						}
	
						.seast-verify-4 {
							background-image: url("https://www.lindiaoren.com/app_images/verify/4.png");
						}
						.seast-verify-5 {
							background-image: url("https://www.lindiaoren.com/app_images/verify/5.png");
						}	
						.seast-verify-6 {
							background-image: url("https://www.lindiaoren.com/app_images/verify/6.png");
						}		
						.seast-verify-7 {
							background-image: url("https://www.lindiaoren.com/app_images/verify/7.png");
							/*女神*/
						}
	
						.seast-verify-8 {
							background-image: url("https://www.lindiaoren.com/app_images/verify/8.png");
						}
						.seast-verify-9 {
							background-image: url("https://www.lindiaoren.com/app_images/verify/9.png");
						}	
						.seast-verify-10 {
							background-image: url("https://www.lindiaoren.com/app_images/verify/10.png");
						}																	
	.avatars {
		margin-right: 15rpx;
		height: 80rpx;
		position: relative;
	
		image {
			width: 80rpx;
			height: 80rpx;
			border-radius: 100%;
		}

	}
	.link{
		color: var(--font_color_link);
	}
	.seast-honor{
		position: relative; 
		text-align: center;
		
	}
	.seast-honor-text{
		white-space: nowrap;
	}		
	.seast-honor-before{
		position: absolute;
	}	
	.seast-honor-after{
		position: absolute; 
	}	
 /*官方头衔*/
	@keyframes yxyhxa {
		0% {
			transform: rotate(0deg)
		}

		50% {
			transform: rotate(360deg)
		}

		100% {
			transform: rotate(0deg)
		}
	}

	@keyframes yxyhxb {
		0% {
			opacity: 0;
			background-image: linear-gradient(to right,#2d74f3 100%,rgba(255,255,255,0) 0)
		}

		50% {
			opacity: 1;
			background-image: linear-gradient(to right,#2d74f3 0,rgba(255,255,255,0) 100%)
		}

		100% {
			opacity: 0;
			background-image: linear-gradient(to right,#2d74f3 100%,rgba(255,255,255,0) 0)
		}
	}

/* #endif */
</style>


<!-- 商城全局样式 -->
<style lang='scss'>
	/*
		全局公共样式和字体图标
	*/
    /*#ifndef APP-NVUE*/  
	@font-face {
		font-family: yticon;
		font-weight: normal;
		font-style: normal;
		src: url('https://at.alicdn.com/t/font_1078604_w4kpxh0rafi.ttf') format('truetype');
	}

	.yticon {
		font-family: "yticon" !important;
		font-size: 16px;
		font-style: normal;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	.icon-yiguoqi1:before {
		content: "\e700";
	}

	.icon-iconfontshanchu1:before {
		content: "\e619";
	}

	.icon-iconfontweixin:before {
		content: "\e611";
	}

	.icon-alipay:before {
		content: "\e636";
	}

	.icon-shang:before {
		content: "\e624";
	}

	.icon-shouye:before {
		content: "\e626";
	}

	.icon-shanchu4:before {
		content: "\e622";
	}

	.icon-xiaoxi:before {
		content: "\e618";
	}

	.icon-jiantour-copy:before {
		content: "\e600";
	}

	.icon-fenxiang2:before {
		content: "\e61e";
	}

	.icon-pingjia:before {
		content: "\e67b";
	}

	.icon-daifukuan:before {
		content: "\e68f";
	}

	.icon-pinglun-copy:before {
		content: "\e612";
	}

	.icon-dianhua-copy:before {
		content: "\e621";
	}

	.icon-shoucang:before {
		content: "\e645";
	}

	.icon-xuanzhong2:before {
		content: "\e62f";
	}

	.icon-gouwuche_:before {
		content: "\e630";
	}

	.icon-icon-test:before {
		content: "\e60c";
	}

	.icon-icon-test1:before {
		content: "\e632";
	}

	.icon-bianji:before {
		content: "\e646";
	}

	.icon-jiazailoading-A:before {
		content: "\e8fc";
	}

	.icon-zuoshang:before {
		content: "\e613";
	}

	.icon-jia2:before {
		content: "\e60a";
	}

	.icon-huifu:before {
		content: "\e68b";
	}

	.icon-sousuo:before {
		content: "\e7ce";
	}

	.icon-arrow-fine-up:before {
		content: "\e601";
	}

	.icon-hot:before {
		content: "\e60e";
	}

	.icon-lishijilu:before {
		content: "\e6b9";
	}

	.icon-zhengxinchaxun-zhifubaoceping-:before {
		content: "\e616";
	}

	.icon-naozhong:before {
		content: "\e64a";
	}

	.icon-xiatubiao--copy:before {
		content: "\e608";
	}

	.icon-shoucang_xuanzhongzhuangtai:before {
		content: "\e6a9";
	}

	.icon-jia1:before {
		content: "\e61c";
	}

	.icon-bangzhu1:before {
		content: "\e63d";
	}

	.icon-arrow-left-bottom:before {
		content: "\e602";
	}

	.icon-arrow-right-bottom:before {
		content: "\e603";
	}

	.icon-arrow-left-top:before {
		content: "\e604";
	}

	.icon-icon--:before {
		content: "\e744";
	}

	.icon-zuojiantou-up:before {
		content: "\e605";
	}

	.icon-xia:before {
		content: "\e62d";
	}

	.icon--jianhao:before {
		content: "\e60b";
	}

	.icon-weixinzhifu:before {
		content: "\e61a";
	}

	.icon-comment:before {
		content: "\e64f";
	}

	.icon-weixin:before {
		content: "\e61f";
	}

	.icon-fenlei1:before {
		content: "\e620";
	}

	.icon-erjiye-yucunkuan:before {
		content: "\e623";
	}

	.icon-Group-:before {
		content: "\e688";
	}

	.icon-you:before {
		content: "\e606";
	}

	.icon-forward:before {
		content: "\e607";
	}

	.icon-tuijian:before {
		content: "\e610";
	}

	.icon-bangzhu:before {
		content: "\e679";
	}

	.icon-share:before {
		content: "\e656";
	}

	.icon-yiguoqi:before {
		content: "\e997";
	}

	.icon-shezhi1:before {
		content: "\e61d";
	}

	.icon-fork:before {
		content: "\e61b";
	}

	.icon-kafei:before {
		content: "\e66a";
	}

	.icon-iLinkapp-:before {
		content: "\e654";
	}

	.icon-saomiao:before {
		content: "\e60d";
	}

	.icon-shezhi:before {
		content: "\e60f";
	}

	.icon-shouhoutuikuan:before {
		content: "\e631";
	}

	.icon-gouwuche:before {
		content: "\e609";
	}

	.icon-dizhi:before {
		content: "\e614";
	}

	.icon-fenlei:before {
		content: "\e706";
	}

	.icon-xingxing:before {
		content: "\e70b";
	}

	.icon-tuandui:before {
		content: "\e633";
	}

	.icon-zuanshi:before {
		content: "\e615";
	}

	.icon-zuo:before {
		content: "\e63c";
	}

	.icon-shoucang2:before {
		content: "\e62e";
	}

	.icon-shouhuodizhi:before {
		content: "\e712";
	}

	.icon-yishouhuo:before {
		content: "\e71a";
	}

	.icon-dianzan-ash:before {
		content: "\e617";
	}





	view,
	scroll-view,
	swiper,
	swiper-item,
	cover-view,
	cover-image,
	icon,
	text,
	rich-text,
	progress,
	button,
	checkbox,
	form,
	input,
	label,
	radio,
	slider,
	switch,
	textarea,
	navigator,
	audio,
	camera,
	image,
	video {

		/* box-sizing: border-box; 
		font-size: var(--font_sm);*/
	}
	/* 骨架屏替代方案 */
	.Skeleton {
		background: #f3f3f3;
		padding: 20rpx 0;
		border-radius: 8rpx;
	}

	/* 图片载入替代方案 */
	.image-wrapper {
		font-size: 0;
		background: #f3f3f3;
		border-radius: 4px;

		image {
			width: 100%;
			height: 100%;
			transition: .6s;
		}
	}

	.clamp {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		display: block;
	}

	.common-hover {
		background-color: var(--bg_color_light);
	}

	/*边框*/
	.b-b:after,
	.b-t:after {
		position: absolute;
		z-index: 3;
		left: 0;
		right: 0;
		height: 0;
		content: '';
		transform: scaleY(.5);
		border-bottom: 1px solid #eeeeee;
	}

	.b-b:after {
		bottom: 0;
	}

	.b-t:after {
		top: 0;
	}

	/* button样式改写 */
	uni-button,
	button {
		height: 80rpx;
		line-height: 80rpx;
		font-size: calc(var(--font_lg) + 2rpx);
		font-weight: normal;

		&.no-border:before,
		&.no-border:after {
			border: 0;
		}
	}

	uni-button[type=default],
	button[type=default] {

	}

	/* input 样式 */
	.input-placeholder {
		color: var(--font_color_light);
	}

	.placeholder {
		color: var(--font_color_light);
	}
	
	::-webkit-scrollbar {
	    width: 0;
	    height: 0;
	    color: transparent;
	  }
	  /* #endif */
</style>
<style>
	/* @import 'https://www.lindiaoren.com/app_images/app.css'; */
</style>