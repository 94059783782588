<template>
	<!-- 选择浏览方式 -->
	<view>
		<uv-popup ref="app" @close="close" @open="open" :round="10" :closeOnClickOverlay="false" :overlayOpacity="0.8" z-index="9999999999999">
			<uv-cell-group title="选择浏览方式" :border="false">
				
			    <uv-cell
					title="临钓人(APP)"
					label="临沂钓鱼人专属APP"
					icon="/static/images/applogo.jpg"
					
					:iconStyle="{width:'100rpx',height:'100rpx',marginRight:'20rpx'}"
				>
				<template v-slot:title>	
					<view
			    	    class="u-slot-title"
						:style="{fontWeight:'bolder',fontSize:'40rpx',display:'flex'}"
			    	>
			    		临钓人
						<view class="appText">
						(
						<text>A</text>
						<text>P</text>
						<text>P</text>
						)	
						</view>
			    	</view>	
					</template>
					<template v-slot:value>
			    	<view
			    	    class="u-slot-value"
			    	>
			    		<uv-button @tap="appOpen" text="打开" type="primary" shape="circle" :customStyle="{width:'200rpx',height:'60rpx'}"></uv-button>
						
			    	</view>					
					</template>
			    </uv-cell>
			    <uv-cell 
					label="通过当前浏览器继续访问"
					icon="/static/images/Safari.png"
					:iconStyle="{width:'100rpx',height:'100rpx'}"					
				>
		<template v-slot:title>		
		<view
		    class="u-slot-title"
			:style="{fontWeight:'bolder',fontSize:'40rpx'}"
		>
			浏览器<text :style="{fontWeight:'100',fontSize:'36rpx',color:'#999999'}">(不推荐)</text>
			
		</view>	
		</template>	
		<template v-slot:value>
					<view
					    class="u-slot-value"
					>
						<uv-button @tap="browserOpen" text="继续"  shape="circle" :customStyle="{width:'200rpx',height:'60rpx'}"></uv-button>
						
					</view>							
					</template>				
			    </uv-cell>
			</uv-cell-group>
		</uv-popup>
	</view>
</template>

<script>

export default {
	props: {
		
	},
	data() {
		return {
			show:false
		};
	},

	methods: {
		
		close() {
			this.$refs.app.close()
		},
		open() {
			this.$refs.app.open('bottom')
			this.$emit('onOpen');
		},
		appOpen(){
			 uni.navigateTo({
			        url: '/pagesA/openAPP/openAPP'
			})			
		},
		browserOpen(){
			this.$refs.app.close()
			uni.setStorageSync('isBrower', true);
		},		
	}
};
</script>

<style scoped lang="scss">
.appText {
	font-size: 35rpx;
	color: #ef9208;
}
.appText text {
	font-style: normal
}
.appText text {
	display: inline-block;
	vertical-align: bottom;
	-webkit-animation-name: fontSizeUp1;
	animation-name: fontSizeUp1;
	-webkit-animation-duration: 1.5s;
	animation-duration: 1.5s;
	-webkit-animation-timing-function: steps(1, end);
	animation-timing-function: steps(1, end);
	-webkit-animation-fill-mode: none;
	animation-fill-mode: none;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	-webkit-transform-origin: 50% 75%;
	transform-origin: 50% 75%
}
.appText text:nth-of-type(1) {
	-webkit-animation-delay: 0s;
	animation-delay: 0s
}
.appText text:nth-of-type(2) {
	-webkit-animation-delay: 0.5s;
	animation-delay: 0.5s
}
.appText text:nth-of-type(3) {
	-webkit-animation-delay: 1s;
	animation-delay: 1s
}
.appText text:nth-of-type(4) {
	-webkit-animation-delay: 1.5s;
	animation-delay: 1.5s
}
.appText text:nth-of-type(5) {
	-webkit-animation-delay: 2s;
	animation-delay: 2s
}
.appText text:nth-of-type(6) {
	-webkit-animation-delay: 2.5s;
	animation-delay: 2.5s
}
.appText text:nth-of-type(7) {
	-webkit-animation-delay: 3s;
	animation-delay: 3s
}
.appText text:nth-of-type(8) {
	-webkit-animation-delay: 3.5s;
	animation-delay: 3.5s
}
.appText text:nth-of-type(9) {
	-webkit-animation-delay: 4s;
	animation-delay: 4s
}
.appText text:nth-of-type(10) {
	-webkit-animation-delay: 4.5s;
	animation-delay: 4.5s
}
.appText text:nth-of-type(11) {
	-webkit-animation-delay: 5s;
	animation-delay: 5s
}
.appText text:nth-of-type(12) {
	-webkit-animation-delay: 5.5s;
	animation-delay: 5.5s
}
@-webkit-keyframes fontSizeUp1 {
0% {
-webkit-transform:scale(1.25);
transform:scale(1.25)
}
7%, 100% {
-webkit-transform:scale(1);
transform:scale(1)
}
}
@keyframes fontSizeUp1 {
0% {
-webkit-transform:scale(1.25);
transform:scale(1.25)
}
7%, 100% {
-webkit-transform:scale(1);
transform:scale(1)
}
}
.appText .fontSizeUp1 {
	-webkit-animation-name: fontSizeUp1;
	animation-name: fontSizeUp1
}

</style>
