/*                                                                             
 *                                                                               
 *  WordPress 小程序资讯版 - UI ： 波波 / Pluigns : 艾码汇 / Marking : M主题@爱折腾的Kit
 *  一款基于 WordPress 网站后端的，采用 WordPress REST API 定制的小程序，做好看的微信小程序
 *  const API_HOST = '此处填写网站域名，需要带上协议'
 */
/*-----------------------------------------------------------*/

const API_HOST = 'https://www.lindiaoren.com' // 更换为你的网站域名, 需要有 https 协议
const IMG_HOST = 'https://www.lindiaoren.com/wp-content/uploads/' // 更换为你的网站域名, 需要有 https 协议
import Auth from '@/api/auth'

const API = {}
API.msg = function(title, duration=1500, mask=false, icon='none'){
	//统一提示方便全局修改
	if(Boolean(title) === false){
		return;
	}
	uni.showToast({
		title,
		duration,
		mask,
		icon
	});
}
API.prePage = function(){
	let pages = getCurrentPages();
	let prePage = pages[pages.length - 2];
	// #ifdef H5
	return prePage;
	// #endif
	return prePage.$vm;
}
API.check =  function() {
	return Auth.check()
}
API.getHost = function() {
	return API_HOST
}
API.getImgHost = function() {
	return IMG_HOST
}
API.getVersion = function(){
	let version_number = '1.0.0'
	const systemInfo = uni.getSystemInfoSync();
	version_number = systemInfo.appVersion;
	return version_number;
}

API.request = function(url, method = "GET", data = {}, args = {
	token: true
}) {

	return new Promise(function(resolve, reject) {
		let version = API.getVersion();
		version = version?version:'';
		url = API_HOST + url;
		if(version){
			if (url.indexOf("?") > 0) {
				url = url + '&ver=' + version;
			} else {
				url = url + '?ver=' + version;
			}
		}
		if (args.token) {
			const token = API.token();
			if (token) {
				if (url.indexOf("?") > 0) {
					url = url + '&access_token=' + token;
				} else {
					url = url + '?access_token=' + token;
				}
			} else {
				console.warn('[提示]', '部分数据需要登录，检测出当前访问用户未登录');
			}
		}

		//console.log( url );
		//console.log( data );
		var header = {};
		if(method=="POST"){
			header = {
					'Content-Type': 'application/x-www-form-urlencoded',
					'X-Requested-With': 'xmlhttprequest'				
			}
		}else{
			header = {
				// #ifdef APP-PLUS||H5
					'Content-Type': 'application/json; charset=UTF-8'
				// #endif	
			}
		}
		uni.request({
			url: url,
			data: data,
			method: method,
			header:  {
				// #ifdef APP-PLUS||H5
					'Content-Type': 'application/json; charset=UTF-8'
				// #endif	
			},
			success: function(res) {
				//console.log( res );
				if (res.statusCode == 200) {
					resolve(res.data);
				} else if (res.data.code === "rest_post_invalid_page_number") {
					uni.showToast({
						title: '没有更多内容',
						mask: false,
						duration: 1000
					});
				} else {
					if (res.data.message) {
						uni.showToast({
							title: res.data.message,
							icon: 'loading',
							duration: 1000
						});
					} else {
						uni.showToast({
							title: '请求数据出错',
							icon: 'loading',
							duration: 1000
						});
					}
					console.log(res.data);
					reject(res.data);
				}
				
			},
			fail: function(err) {
				console.log(err);
				reject(err);
			}
		})
	});

}

API.get = function(url, data = {}, args = {
	token: false
}) {
	return API.request(url, "GET", data, args);
}

API.post = function(url, data, args = {
	token: true
}) {
	return API.request(url, "POST", data, args);
}

API.getUser = function() {
	if (Auth.check()) {
		return Auth.user();
		
	} else {
		return '';
	}
}

API.login = function() {

		if (Auth.check()) {
			API.get('/wp-json/wp/v2/user/check', {}, {
				token: true
			}).then(res => {
				console.log(res)
				if(res.status == 200){
					return true;
				}else{
					uni.navigateTo({
						url:'/member/login/login'
					})
					
				}
			}, err => {
					uni.navigateTo({
						url:'/member/login/login'
					})
				return err;
			});

		} else {
			uni.navigateTo({
				url:'/member/login/login'
			})
			
		}

}

API.logout = function() {
	let userId = uni.getStorageSync('user')
	API.post('/wp-json/wp/v2/site/logout', {}, {
		token: true
	});	
	if (Auth.logout()) {
		uni.setStorageSync('oldUserId', userId);//记录注销前用户ID

	} else {
		uni.showToast({
			title: '注销失败!',
			icon: 'loading',
			duration: 1000,
		})
	}
}

API.getProfiles = function() {
	return new Promise(function(resolve, reject) {
		Auth.getUserInfo().then(data => {
			let invite = uni.getStorageSync('invite');
			if(invite) data.invite =  invite;
				API.post('/wp-json/wp/v2/wechat/login', data, {
					token: false
				}).then(res => {
					//console.log( res );
					API.storageUser(res);
					resolve(res.user);
				}, err => {
					reject(err);
				});
			})
			.catch(err => {
				//console.log( err );
				reject(err);
			})
	});
}



API.getUserInfos = function(e) {
	return new Promise(function(resolve, reject) {
		Auth.getUserInfoByButton(e).then(data=>{
			// #ifdef MP-WEIXIN
			 var url='/wp-json/mp/v2/wechat/login';
			// #endif
			// #ifdef MP-BAIDU
			var url='/wp-json/wp/v2/baidu/login';
			// #endif
			// #ifdef MP-QQ
			var url='/wp-json/wp/v2/tencent/login';
			// #endif
			// #ifdef MP-TOUTIAO
			var url='/wp-json/wp/v2/toutiao/login';
			// #endif
			let invite = uni.getStorageSync('invite');
			if(invite) data.invite =  invite;
			API.post(url, data, { token: false }).then(res => {
				API.storageUser(res);
				console.log(res);
				resolve(res.user);
			}, err => {
				reject(err);
			});
		})
		.catch( err =>{
			reject(err);
		})
	});
}

API.getTtProfile = function() {
	return new Promise(function(resolve, reject) {
		Auth.getTtUserInfo().then(data=>{
			API.post('/wp-json/wp/v2/toutiao/login', data, { token: false }).then(res => {
				API.storageUser(res);
				console.log(res);
				resolve(res.user);
			}, err => {
				reject(err);
			});
		})
		.catch( err =>{
			reject(err);
		})
	});
}

API.bloginfo = function() {
	return new Promise(function(resolve, reject) {
		API.get('/wp-json/wp/v2/setting').then(res => {
			//console.log( res )
			uni.setStorageSync('bloginfo', res);
			resolve(res);
		}, err => {
			reject(err);
		});
	});
}

API.token = function() {
	if (Auth.token() && Date.now()/1000 < uni.getStorageSync('expired_in')) {
		return Auth.token();
	} else {
		return '';
	}
}

API.openid = function() {
	let openid = Auth.openid();
	if(openid){
		return openid;
	}else{
		let platform = uni.getSystemInfoSync().uniPlatform
		API.get('/wp-json/wp/v2/site/openid',{platform}).then(res => {
			if(res){
				uni.setStorageSync('_USER_OPENID', res);
				return res;
			}else{
				return ;
			}
		}, err => {
			return err;
		});
	}
}

API.storageUser = function(res) {
	if(res.user){
		uni.setStorageSync('user', res.user);
		uni.setStorageSync('_USER_OPENID', res.openid);
		//uni.setStorageSync('_SESSION_KEY', res.session_key);
		if (res.unionid) {
			uni.setStorageSync('_USER_UNIONID', res.unionid);
		}
		if (res.access_token) {
			uni.setStorageSync('token', res.access_token);
			uni.setStorageSync('expired_in', res.expired_in);
		}
	}
}

API.guard = function(fn) {
	const self = this
	return function() {
		if (API.getUser()) {
			return fn.apply(self, arguments)
		} else {
			return API.getProfile().then(res => {
				console.log('登录成功', res);
				return fn.apply(self, arguments)
			}, err => {
				console.log('登录失败', err);
				return err
			})
		}
	}
}

API.template = function() {
	return new Promise(function(resolve, reject) {
		API.get('/wp-json/wp/v2/subscribe/templates').then(res => {
			if (res.status == 200) {
				uni.setStorageSync('templates', res.data);
			}
			resolve(res);
		}, err => {
			reject(err);
		});
	});
}


/**
 * 上传文件至媒体库
 * @param	{Files} 
 * @return {Promise}
 */
API.upload = function(url, files = "", args = {
	token: true
}) {
	return new Promise(function(resolve, reject) {
		url = API_HOST + url;
		let version = API.getVersion();
		version = version?version:'';
		if(version){
			if (url.indexOf("?") > 0) {
				url = url + '&ver=' + version;
			} else {
				url = url + '?ver=' + version;
			}
		}
		if (args.token) {
			const token = API.token();
			if (token) {
				if (url.indexOf("?") > 0) {
					url = url + '&access_token=' + token;
				} else {
					url = url + '?access_token=' + token;
				}
			} else {
				console.warn('[提示]', '需要授权才能上传图片，检测出当前访问用户未授权登录小程序');
				uni.showModal({
					title: '温馨提示',
					content: '上传图片需要授权登录',
					success: function(res) {
						if (res.confirm) {
							API.getUserInfo()
						}
					}
				})
			}
		} else {
			console.warn('[提示]', '需要授权才能上传图片');
			uni.showModal({
				title: '温馨提示',
				content: '上传图片需要授权登录'
			})
		}
		
		const uploadTask = uni.uploadFile({
			url: url,
			filePath: files,
			name: 'file',
			success: function(res) {
				if (res.statusCode == 200) {
					let data = JSON.parse(res.data);
					if (data.code == 1) {
						console.log("上传成功")
						resolve(data);
					} else{
						console.log(data)
						uni.showModal({
							title: '温馨提示',
							content: data.msg
						})
						uni.hideLoading();
						resolve(data)
					}
				} else {
					console.log("上传失败")
					// uni.navigateTo({
					// 	url: '/member/login/login'
					// })

					reject(res.data)
				}
			},
			fail: function(err) {
				console.log(err)
				reject(err)
			}
		})
	})
}
API.powertype = function(){
	var powerList = [{
						name: "【公开】——所有用户可以浏览",
						type: 0,
						icon:"seast-gongkai1",
						text:"公开"
					},
					{
						name: "【付费】——需要付费可以浏览",
						type: 1,
						icon:"seast-fufei",
						text:"付费"
					},
					{
						name: "【密码】——需要密码可以浏览",
						type: 2,
						icon:"seast-mima",
						text:"密码"
					},
					{
						name: "【私密】——只有自己可以浏览",
						type: 3,
						icon:"seast-biyan",
						text:"私密"
					},
					{
						name: "【VIP】——VIP用户可以浏览",
						type: 4,
						icon:"seast-vip-type",
						text:"VIP"
					},
					{
						name: "【登录】——需要登录可以浏览",
						type: 5,
						icon:"seast-denglu",
						text:"登录"
					}
				]
	return  powerList;
}

API.sendPushMessage = function(noticeTitle,noticeContent,noticePayload){
	var options = {
		cover: false,
		sound: 'system',
		title: noticeTitle,
		// 
	};

	let payload = JSON.stringify(noticePayload);
	API.palysmsvoice()
	plus.push.createMessage(noticeContent, payload, options);
}

API.palysmsvoice = function(){
	const innerAudioContext = uni.createInnerAudioContext();
	innerAudioContext.src = IMG_HOST+'sound/1.mp3';
	innerAudioContext.play()
}

API.imageCompress = function(file,index,obj){
	return new Promise((resolve, reject) => {
		let type = ''
		let quality = 80
		//大于1M进行压缩，
		if (file.size < (1024 * 1024)) {
			resolve(file)
			return false
		} else {
			if (file.size > (1024 * 1024 * 2.5)) {
				quality = 60
			}
			if (file.size > (1024 * 1024 * 5)) {
				quality = 40
			}	
			if (file.size > (1024 * 1024 * 10)) {
				quality = 25
			}					
		}
		var that = obj
		uni.getImageInfo({
			src: file.thumb,
			success: (imageRes) => {
				let canvasWidth = imageRes.width // 图片原始长宽
				let canvasHeight = imageRes.height;
				let base = canvasWidth/canvasHeight;

				if( canvasWidth > 1000 ){
					var compressWidth = 1000
					var compressHeight = Math.floor(compressWidth/base);
				}else{
					var compressWidth = imageRes.width
					var compressHeight = imageRes.height
				}
					let newCanvas = {
						width:compressWidth,
						height:compressHeight 
					}
					
						that.changeCanvas(index,newCanvas)
					
					

				
				// #ifdef APP-PLUS || MP-WEIXIN
					uni.compressImage({
						src: file.thumb,
						quality: quality,
						width: compressWidth+'px',
						compressedWidth: compressWidth,
						success: res => {
							let newPath = res.tempFilePath
							let newName = res.tempFilePath.split("/")[res.tempFilePath.split("/").length - 1]
							// #ifdef MP-WEIXIN
							uni.getFileSystemManager().getFileInfo({
								filePath: res.tempFilePath,
								success: async (info) => {
								
									let newFile = {
										size: info.size,
										thumb: newPath,
										name: newName,
										tempFilePath: res.tempFilePath
									}
									resolve(await API.imageCompress(newFile,index,that))
								}
							})						
							// #endif
							// #ifndef MP-WEIXIN
							uni.getFileInfo({
								filePath: res.tempFilePath,
								success: async (info) => {
									let newFile = {
										size: info.size,
										thumb: newPath,
										name: newName,
										tempFilePath: res.tempFilePath
									}
									resolve(await API.imageCompress(newFile,index,that))
								}
							})
							// #endif
						}
					})					
				// #endif
				
				// #ifndef APP-PLUS && MP-WEIXIN
						setTimeout(()=>{
							
							const context = uni.createCanvasContext('canvas'+index);
							
							/* 
									canvas宽高重新设置后会有一段渲染的间隔，这个间隔可能导致画到画布的图片出现大小不一致的bug，这里做下300ms延迟处理兼容
							 */
							
									let pixelRatio = uni.getSystemInfo().pixelRatio
									context.clearRect(0, 0, canvasWidth, canvasHeight);
									context.drawImage(imageRes.path, 0, 0,canvasWidth,canvasHeight,0,0, compressWidth, compressHeight);
									context.draw(false, () => {
							
										// 将图片画到canvas上面，使用Canvas压缩
										uni.canvasToTempFilePath({
											x: 0,
											y: 0,
										  width: compressWidth,
										  height: compressHeight,
										  destWidth: compressWidth*pixelRatio,
										  destHeight: compressHeight*pixelRatio,
										  canvasId: 'canvas'+index,
										  fileType: 'jpg',
										  quality: quality/100,
										  success: (res) => {
											  let newPath = res.tempFilePath
											  let newName = res.tempFilePath.split("/")[res.tempFilePath.split("/").length - 1]
											// 在H5平台下，tempFilePath 为 base64
											uni.getFileInfo({
												filePath: res.tempFilePath,
												success: async (info) => {
													let newFile = {
														size: info.size,
														thumb: newPath,
														name: newName,
														tempFilePath: res.tempFilePath
													}
							
													resolve(await API.imageCompress(newFile,index,that))
												}
											})	
												
										  },
											fail: (err)=>{
												console.log(err)
											}
										}, this);
									});
							
						},1000)
						
							
	
				// #endif
				
			}
		})
		
 
	})
 
}


API.videoCompress = function(file){
	return new Promise((resolve, reject) => {
		let type = ''
		let quality ='high'
		//大于1M进行压缩，
		if (file.size < (1024 * 1024 * 10)) {
			resolve(file)
			return false
		} else {
			if (file.size > (1024 * 1024 * 20)) {
				quality = 'high'
			}
			if (file.size > (1024 * 1024 * 50)) {
				quality = 'medium'
			}	
			if (file.size > (1024 * 1024 * 100)) {
				quality = 'low'
			}					
		}
		var that = obj
		// #ifdef APP-PLUS || MP-WEIXIN
		uni.compressVideo({
			src: file.tempFilePath,  
			quality: quality, //'low':低，'medium':中，'high':高  
			success: async(res)=>{            
				console.log('压缩后',res)
				let newFile = {
					size: res.size,
					tempFilePath: res.tempFilePath
				}
				resolve(await API.videoCompress(newFile))
			},
			fail: function (err) {
				uni.showToast({  
					title:'视频压缩失败',  
					icon:'none'
				},2000)
			}
		})
		// #endif
				
		// #ifndef APP-PLUS && MP-WEIXIN
				setTimeout(()=>{
					
					resolve(file)
				},1000)
				
		// #endif
				
		})
		
 
}

API.navigateBack = function(params) {
	const pages = getCurrentPages()
	if (pages.length === 1) {
	  if (typeof params === 'number') {
		history.go(-params)
	  } else {
		history.back()
	  }
	} else {
	  uni.navigateBack()
	}
}

export default API
 