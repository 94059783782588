import API  from '@/api/base'

const getSiteInfo = function(data) {
	return API.get('/wp-json/wp/v2/setting', data,{
		token: true
	});
}

const getSetting = function(data) {
	return API.get('/wp-json/wp/v2/setting/get-var', data);
}
const getEdition = function(data) {
	return API.get('/wp-json/wp/v2/setting/get-edition', data);
}
const getLastestEdition = function(data) {
	return API.get('/wp-json/wp/v2/setting/get-lastest-edition', data);
}
const getCitys = function(data) {
	return API.get('/app/regions.json', data);
}
/**
 * 获取用户信息
 * @param  {object} args 参数
 * @return {promise}
 */
const getProfile = function(e) {
	return API.getUserInfo(e);
}

const palySmsVoice = function() {
	return API.palysmsvoice();
}
const getCode = function(data) {
	return API.post('/wp-json/wp/v2/setting/code', data, {
		token: true
	});
}
const getPhone = function(data) {
	return API.post('/wp-json/wp/v2/site/get-phone', data, {
		token: true
	});
}

const getBanner = function(data) {
	// #ifdef MP-WEIXIN
	return API.get('/wp-json/wp/v2/banner?from=wechat',data);
	// #endif
	// #ifdef MP-QQ
	return API.get('/wp-json/wp/v2/banner?from=qq',data);
	// #endif
	// #ifdef MP-BAIDU
	return API.get('/wp-json/wp/v2/banner?from=baidu',data);
	// #endif
	// #ifdef MP-TOUTIAO
	return API.get('/wp-json/wp/v2/banner?from=toutiao',data);
	// #endif
	// #ifndef MP
	return API.get('/wp-json/wp/v2/banner?from=wechat',data);
	// #endif
	// #ifndef APP
	return API.get('/wp-json/wp/v2/banner?from=app',data);
	// #endif
}

const getWechat = function(data) {
	return API.get('/wp-json/wp/v2/wechat', data,{
		token: true
	});
}

const getWechatMp = function(data) {
	return API.get('/wp-json/wp/v2/wechat/mp', data,{
		token: true
	});
}

const getWechatUrl = function(data) {
	return API.get('/wp-json/wp/v2/wechat/mp-login-url', data,{
		token: true
	});
}

const getQQ = function(data) {
	return API.get('/wp-json/wp/v2/qq', data,{
		token: true
	});
}

const getWeibo = function(data) {
	return API.get('/wp-json/wp/v2/weibo', data,{
		token: true
	});
}

const getStickyPosts = function(data) {
	return API.get('/wp-json/wp/v2/posts/sticky', data);
}

const getPostsList = function(data) {
	return API.get('/wp-json/wp/v2/posts', data, {
		token: true
	});
}

const getDiaochangList = function(data) {
	return API.get('/wp-json/wp/v2/posts/diaochang', data, {
		token: true
	});
}

const getDianpuList = function(data) {
	return API.get('/wp-json/wp/v2/posts/dianpu', data, {
		token: true
	});
}

const getRelatePosts = function(data) {
	return API.get('/wp-json/wp/v2/posts/relate', data);
}

const getPostsbyID = function(id) {
	return API.get('/wp-json/wp/v2/posts/detail?id=' + id, {}, {
		token: true
	});
}
const getEditPosts = function(id) {
	return API.get('/wp-json/wp/v2/posts/edit-detail?id=' + id, {}, {
		token: true
	});
}

const updateViewPosts = function(id) {
	return API.post('/wp-json/wp/v2/posts/views?id=' + id, {}, {
		token: true
	});
}

const getPagesList = function(data) {
	return API.get('/wp-json/wp/v2/pages', data);
}

const getPageByID = function(id) {
	return API.get('/wp-json/wp/v2/pages/' + id);
}

const getCategories = function(data) {
	return API.get('/wp-json/wp/v2/bbs?orderby=id&order=asc', data);
}

const getCategoryByID = function(id) {
	return API.get('/wp-json/wp/v2/bbs/' + id,{},{
		token: true
	});
}

const getBbsLike = function(data) {
	return API.get('/wp-json/wp/v2/bbs/bbs-like',data,{
		token: true
	});
}

const getBbsList = function(data) {
	return API.get('/wp-json/wp/v2/bbs/bbs-list',data,{
		token: true
	});
}

const getBbsPost = function(data) {
	return API.get('/wp-json/wp/v2/bbs/post',data,{
		token: true
	});
}

const getBbsPassword = function(data) {
	return API.post('/wp-json/wp/v2/bbs/password',data,{
		token: true
	});
}

const pageNoPower = function(data) {
	return API.get('/wp-json/wp/v2/bbs/page-no-power',data,{
		token: true
	});
}

const getTags = function(data) {
	//return API.get('/wp-json/wp/v2/tags?orderby=rand&order=asc', data);
	return API.get('/wp-json/wp/v2/tags', data);
}

const getTagsList = function(data) {
	return API.get('/wp-json/wp/v2/tags', data);
}

const getTagByID = function(id) {
	return API.get('/wp-json/wp/v2/tags/' + id);
}

const getRandPosts = function(data) {
	return API.get('/wp-json/wp/v2/posts?orderby=rand', data,{
		token: true
	});
}


const getMostViewsPosts = function(data) {
	return API.get('/wp-json/wp/v2/posts/most?meta=post_views', data,{
		token: true
	});
}

const getMostFavPosts = function(data) {
	return API.get('/wp-json/wp/v2/posts/most?meta=favs', data,{
		token: true
	});
}

const getMostLikePosts = function(data) {
	return API.get('/wp-json/wp/v2/posts/most?meta=likes', data,{
		token: true
	});
}

const getMostCommentPosts = function(data) {
	return API.get('/wp-json/wp/v2/posts/most?meta=comments', data,{
		token: true
	});
}

const getRecentCommentPosts = function(data) {
	return API.get('/wp-json/wp/v2/comments/recent', data,{
		token: true
	});
}

const getComments = function(data) {
	return API.get('/wp-json/wp/v2/comments', data, {
		token: true
	});
}

const getReplys = function(data) {
	return API.get('/wp-json/wp/v2/comments/replys', data, {
		token: true
	});
}

const Login = function() {
	return API.login();
}



const fav = function(data) {
	return API.post('/wp-json/wp/v2/comments?type=fav', data, {
		token: true
	});
}

const like = function(data) {
	return API.post('/wp-json/wp/v2/comments?type=like', data, {
		token: true
	});
}

const getMyPosts = function(data) {
	return API.get('/wp-json/wp/v2/mine/posts', data, {
		token: true
	});
}

const getCollectPosts = function(data) {
	return API.get('/wp-json/wp/v2/collect/posts', data, {
		token: true
	});
}

const getCommentsPosts = function(data) {
	return API.get('/wp-json/wp/v2/posts/comment', data, {
		token: true
	});
}

const getLikePosts = function(data) {
	return API.get('/wp-json/wp/v2/posts/like', data, {
		token: true
	});
}

const addComment = function(data) {
	return API.post('/wp-json/wp/v2/comments?type=comment', data, {
		token: true
	});
}

const addBbsComment = function(data) {
	return API.post('/wp-json/wp/v2/comments/add-bbs-comment', data, {
		token: true
	});
}

const commentAnswer = function(data) {
	return API.post('/wp-json/wp/v2/comment-manage/answer', data, {
		token: true
	});
}

const commentDel = function(data) {
	return API.post('/wp-json/wp/v2/comment-manage/del', data, {
		token: true
	});
}
const commentUp = function(data) {
	return API.post('/wp-json/wp/v2/comment-manage/up', data, {
		token: true
	});
}

const addBooks = function(data) {
	return API.post('/wp-json/wp/v2/posts?type=books', data, {
		token: true
	});
}

const getCodeImg = function(data) {
	return API.post('/wp-json/wp/v2/qrcode', data, {
		token: false
	});
}

const getMenu = function(data) {
	return API.get('/wp-json/wp/v2/menu', data);
}

const getTab = function(data) {
	return API.get('/wp-json/wp/v2/menu/tab', data);
}

const indexAdsense = function(data) {
	// #ifdef APP-PLUS
	return API.get('/wp-json/wp/v2/advert/app?type=index', data);
	// #endif	
	// #ifdef H5
	return API.get('/wp-json/wp/v2/advert/h5?type=index', data);
	// #endif	
	// #ifdef MP-WEIXIN
	return API.get('/wp-json/wp/v2/advert/wechat?type=index', data);
	// #endif
	// #ifdef MP-QQ
	return API.get('/wp-json/wp/v2/advert/qq?type=index', data);
	// #endif
	// #ifdef MP-BAIDU
	return API.get('/wp-json/wp/v2/advert/baidu?type=index', data);
	// #endif
	// #ifdef MP-TOUTIAO
	return API.get('/wp-json/wp/v2/advert/toutiao?type=index', data);
	// #endif
}

const listAdsense = function(data) {
	// #ifdef APP-PLUS
	return API.get('/wp-json/wp/v2/advert/app?type=list', data);
	// #endif	
	// #ifdef H5
	return API.get('/wp-json/wp/v2/advert/h5?type=list', data);//H5页面测试APP效果
	// #endif	
	// #ifdef MP-WEIXIN
	return API.get('/wp-json/wp/v2/advert/wechat?type=list', data);
	// #endif
	// #ifdef MP-QQ
	return API.get('/wp-json/wp/v2/advert/qq?type=list', data);
	// #endif
	// #ifdef MP-BAIDU
	return API.get('/wp-json/wp/v2/advert/baidu?type=list', data);
	// #endif
	// #ifdef MP-TOUTIAO
	return API.get('/wp-json/wp/v2/advert/toutiao?type=list', data);
	// #endif
}

const detailAdsense = function(data) {
	// #ifdef APP-PLUS
	return API.get('/wp-json/wp/v2/advert/app?type=detail', data);
	// #endif	
	// #ifdef H5
	return API.get('/wp-json/wp/v2/advert/h5?type=detail', data);
	// #endif	
	// #ifdef MP-WEIXIN
	return API.get('/wp-json/wp/v2/advert/wechat?type=detail', data);
	// #endif
	// #ifdef MP-QQ
	return API.get('/wp-json/wp/v2/advert/qq?type=detail', data);
	// #endif
	// #ifdef MP-BAIDU
	return API.get('/wp-json/wp/v2/advert/baidu?type=detail', data);
	// #endif
	// #ifdef MP-TOUTIAO
	return API.get('/wp-json/wp/v2/advert/toutiao?type=detail', data);
	// #endif
}

const pageAdsense = function(data) {
	return API.get('/wp-json/wp/v2/advert/wechat?type=page', data);
}

const insertAdsense = function(data) {
	return API.get('/wp-json/wp/v2/advert/wechat?type=insert', data);
}

const videoAdsense = function(data) {
	// #ifdef MP-WEIXIN
	return API.get('/wp-json/wp/v2/advert/wechat?type=video', data);
	// #endif
	// #ifdef MP-QQ
	return API.get('/wp-json/wp/v2/advert/qq?type=video', data);
	// #endif
	// #ifdef MP-BAIDU
	return API.get('/wp-json/wp/v2/advert/baidu?type=video', data);
	// #endif
	// #ifdef MP-TOUTIAO
	return API.get('/wp-json/wp/v2/advert/toutiao?type=video', data);
	// #endif
}

const checkUser = function(data) {
	return API.get('/wp-json/wp/v2/user/check', data, {
		token: true
	})
}

const checkPass = function(data) {
	return API.get('/wp-json/wp/v2/user/check-pass', data, {
		token: true
	})
}

const delApply = function(data) {
	return API.post('/wp-json/wp/v2/user/del-apply', data, {
		token: true
	})
}

const getUserById = function(data) {
	return API.get('/wp-json/wp/v2/user/view', data);
}

const getMineInfo = function(data) {
	return API.get('/wp-json/wp/v2/user/mine', data, {
		token: true
	});
}

const updateProfile = function(data) {
	return API.post('/wp-json/wp/v2/user/profile', data, {
		token: true
	});
}


const updateSetting = function(data) {
	return API.post('/wp-json/wp/v2/user/setting', data, {
		token: true
	});
}

const updateUserStatus = function(data) {
	return API.post('/wp-json/wp/v2/user/update-status', data, {
		token: true
	});
}

const getAuthorInfo = function(data) {
	return API.get('/wp-json/wp/v2/user/user-info', data, {
		token: true
	});
}

const getNearbyUsers = function(data) {
	return API.get('/wp-json/wp/v2/user/nearby-user', data, {
		token: true
	});
}

const getNearbyDianpu = function(data) {
	return API.get('/wp-json/wp/v2/user/nearby-dianpu', data, {
		token: true
	});
}

const getNearbyDiaochang = function(data) {
	return API.get('/wp-json/wp/v2/user/nearby-diaochang', data, {
		token: true
	});
}



const getRanking = function(data) {
	return API.get('/wp-json/wp/v2/user/ranking', data)
}

const subscribe = function(data) {
	return API.post('/wp-json/wp/v2/subscribe', data, {
		token: true
	})
}

const markComment = function(data) {
	return API.post('/wp-json/wp/v2/comments/mark', data, {
		token: true,
	})
}

const getVideoList = function(args) {
	return API.get('/wp-json/wp/v2/posts?format=video', args)
}

const acountlogin = function(data) {
	return API.post('/wp-json/wp/v2/site/login', data);
}

const getPassWord = function(data) {
	return API.post('/wp-json/wp/v2/site/lostpass',data);
}

const getEmailCode = function(data) {
	return API.post('/wp-json/wp/v2/site/code', data);
}

const getRegistra = function(data) {
	return API.post('/wp-json/wp/v2/site/signup', data);
}

const logoffUser = function(data) {
	return API.post('/wp-json/wp/v2/site/logoff', data, { token: true });
}

const getPassInfo = function(data) {
	return API.get('/wp-json/wp/v2/site/getpass', data);
}

const upLoadImage=function(filePath){
	return 	API.upload('/wp-json/wp/v2/media', filePath, { token: true })
}


const getNotices =function(args) {
  return API.get('/wp-json/wp/v2/notice', args, {
    token: true
  });
}

const getNoticesPush =function(args) {
  return API.get('/wp-json/wp/v2/notice/push', args, {
    token: true
  });
}

const getNoticeDetail= function  (args) {
  return API.get('/wp-json/wp/v2/notice/view', args, {
    token: true
  });
}

const setNoticeStatus= function  (args) {
  return API.get('/wp-json/wp/v2/notice/set-status', args, {
    token: true
  });
}

const setNoticePushStatus= function  (args) {
  return API.get('/wp-json/wp/v2/notice/set-push-status', args, {
    token: true
  });
}

const getNoticeTabs= function  (args) {
  return API.get('/wp-json/wp/v2/notice/tabs', args, {
    token: true
  });
}

const uploadWords= function  (filePath) {
  return API.upload('/wp-json/wp/v2/upload/words', filePath, {
    token: true
  });
}

const uploadBBS= function  (filePath) {
  return API.upload('/wp-json/wp/v2/upload/bbs', filePath, {
    token: true
  });
}

const uploadVideo= function  (filePath) {
  return API.upload('/wp-json/wp/v2/upload/video', filePath, {
    token: true
  });
}

const uploadVideoImg= function  (filePath) {
	return API.upload('/wp-json/wp/v2/upload/video-img', filePath, {
	  token: true
	});
}

const uploadVideoImg64= function  (data) {
  return API.post('/wp-json/wp/v2/upload/video-img-base64', data, {
    token: true
  });
}

const uploadDiaochang= function  (filePath) {
  return API.upload('/wp-json/wp/v2/upload/diaochang', filePath, {
    token: true
  });
}

const uploadDianpu= function  (filePath) {
  return API.upload('/wp-json/wp/v2/upload/dianpu', filePath, {
    token: true
  });
}

const uploadPlaces= function  (filePath) {
  return API.upload('/wp-json/wp/v2/upload/places', filePath, {
    token: true
  });
}

const uploadComment= function  (filePath) {
  return API.upload('/wp-json/wp/v2/upload/comment', filePath, {
    token: true
  });
}

const uploadImOne= function  (filePath) {
  return API.upload('/wp-json/wp/v2/upload/im-one', filePath, {
    token: true
  });
}

const uploadImGroup= function  (filePath) {
  return API.upload('/wp-json/wp/v2/upload/im-group', filePath, {
    token: true
  });
}

const uploadAvatar= function  (filePath) {
  return API.upload('/wp-json/wp/v2/upload/avatar', filePath, {
    token: true
  });
}

const uploadAvatar64= function  (data) {
  return API.post('/wp-json/wp/v2/upload/avatar-base64', data, {
    token: true
  });
}

const uploadSkin= function  (filePath) {
  return API.upload('/wp-json/wp/v2/upload/skin', filePath, {
    token: true
  });
}


const uploadFile= function  (filePath) {
  return API.upload('/wp-json/wp/v2/upload/file', filePath, {
    token: true
  });
}

const uploadSecondhand= function  (filePath) {
  return API.upload('/wp-json/wp/v2/upload/secondhand', filePath, {
    token: true
  });
}


const getTopic= function  (data) {
  return API.get('/wp-json/wp/v2/topic', data, {
    token: true
  });
}

const getTopicSetting= function  (data) {
  return API.get('/wp-json/wp/v2/topic/setting', data, {
    token: true
  });
}

const getHotTopic= function  (data) {
  return API.get('/wp-json/wp/v2/topic/hot-topic', data, {
    token: true
  });
}

const searchTopic= function  (data) {
  return API.get('/wp-json/wp/v2/topic/search', data, {
    token: true
  });
}

const getTopicDetail= function  (id) {
  return API.get('/wp-json/wp/v2/topic/'+ id, {}, {
    token: true
  });
}

const topicLike= function  (data) {
  return API.get('/wp-json/wp/v2/topic/like',data, {
    token: true
  });
}

const getTopicLike = function(data) {
	return API.get('/wp-json/wp/v2/topic/topic-like',data,{
		token: true
	});
}

const bbsLike= function  (data) {
  return API.get('/wp-json/wp/v2/bbs/like',data, {
    token: true
  });
}

const searchDiaochang= function  (data) {
  return API.get('/wp-json/wp/v2/posts/search-diaochang', data, {
    token: true
  });
}

const hotDiaochang= function  (data) {
  return API.get('/wp-json/wp/v2/posts/hot-diaochang', data, {
    token: true
  });
}

const hotDianpu= function  (data) {
  return API.get('/wp-json/wp/v2/posts/hot-dianpu', data, {
    token: true
  });
}

const searchUser= function  (data) {
  return API.get('/wp-json/wp/v2/user/search', data, {
    token: true
  });
}

const publishWords = function(data) {
	return API.post('/wp-json/wp/v2/publish/words', data, {
		token: true
	});
}

const publishSingle = function(data) {
	return API.post('/wp-json/wp/v2/publish/single', data, {
		token: true
	});
}

const publishVideo = function(data) {
	return API.post('/wp-json/wp/v2/publish/video', data, {
		token: true
	});
}

const publishBbs = function(data) {
	return API.post('/wp-json/wp/v2/publish/bbs', data, {
		token: true
	});
}

const publishDiaochang = function(data) {
	return API.post('/wp-json/wp/v2/publish/diaochang', data, {
		token: true
	});
}

const publishDianpu = function(data) {
	return API.post('/wp-json/wp/v2/publish/dianpu', data, {
		token: true
	});
}

const publishPlaces = function(data) {
	return API.post('/wp-json/wp/v2/publish/places', data, {
		token: true
	});
}

const publishRedbag = function(data) {
	return API.post('/wp-json/wp/v2/publish/redbag', data, {
		token: true
	});
}

const publishSecondhand = function(data) {
	return API.post('/wp-json/wp/v2/publish/secondhand', data, {
		token: true
	});
}

const checkDiaochang = function(data) {
	return API.post('/wp-json/wp/v2/publish/diaochang/check', data, {
		token: true
	});
}

const checkDianpu = function(data) {
	return API.post('/wp-json/wp/v2/publish/dianpu/check', data, {
		token: true
	});
}

const getPassword = function(data) {
	return API.post('/wp-json/wp/v2/publish/power/password', data, {
		token: true
	});
}

const getPay = function(data) {
	return API.post('/wp-json/wp/v2/publish/power/pay', data, {
		token: true
	});
}

const checkTopic = function(data) {
	return API.post('/wp-json/wp/v2/publish/power/topic', data, {
		token: true
	});
}

const getBbsPay = function(data) {
	return API.post('/wp-json/wp/v2/bbs/pay', data, {
		token: true
	});
}

const doCollect = function(data) {
	return API.post('/wp-json/wp/v2/collect', data, {
		token: true
	});
}
const getRedbag = function(data) {
	return API.get('/wp-json/wp/v2/redbag', data, {
		token: true
	});
}

const getRedbagSetting = function(data) {
	return API.get('/wp-json/wp/v2/redbag/setting', data, {
		token: true
	});
}
const getFollow = function(data) {
	return API.post('/wp-json/wp/v2/user/follow', data, {
		token: true
	});
}
const getFollowers = function(data) {
	return API.get('/wp-json/wp/v2/user/followers', data, {
		token: true
	});
}
const getVisitors = function(data) {
	return API.get('/wp-json/wp/v2/user/visitors', data, {
		token: true
	});
}

const getBlacklist = function(data) {
	return API.get('/wp-json/wp/v2/user/blacklist', data, {
		token: true
	});
}

const removeBlacklist = function(data) {
	return API.get('/wp-json/wp/v2/user/remove-blacklist', data, {
		token: true
	});
}

const getOnline = function(data) {
	return API.get('/wp-json/wp/v2/user/online', data, {
		token: true
	});
}

const getCommentLikes = function(data) {
	return API.get('/wp-json/wp/v2/user/comment-likes', data, {
		token: true
	});
}

const getSign = function(data) {
	return API.get('/wp-json/wp/v2/sign/', data, {
		token: true
	});
}
const addSign = function(data) {
	return API.post('/wp-json/wp/v2/sign/add', data, {
		token: true
	});
}
const daySign = function(data) {
	return API.post('/wp-json/wp/v2/sign/day', data, {
		token: true
	});
}
const taskSign = function(data) {
	return API.post('/wp-json/wp/v2/sign/task', data, {
		token: true
	});
}
const taskContentSign = function(data) {
	return API.get('/wp-json/wp/v2/sign/task-content', data, {
		token: true
	});
}


const getMessage = function(data) {
	return API.get('/wp-json/wp/v2/message/', data, {
		token: true
	});
}

const getMessageList = function(data) {
	return API.get('/wp-json/wp/v2/message/list', data, {
		token: true
	});
}

const sendMessage = function(data) {
	return API.post('/wp-json/wp/v2/message/send', data, {
		token: true
	});
}


const deleteChatList = function(data) {
	return API.post('/wp-json/wp/v2/message/delete-list', data, {
		token: true
	});
}

const getGroupMessage = function(data) {
	return API.get('/wp-json/wp/v2/group-message/', data, {
		token: true
	});
}

const sendGroupMessage = function(data) {
	return API.post('/wp-json/wp/v2/group-message/send', data, {
		token: true
	});
}

const getGroupMessageList = function(data) {
	return API.get('/wp-json/wp/v2/group-message/list', data, {
		token: true
	});
}

const getTaskList = function(data) {
	return API.get('/wp-json/wp/v2/task', data, {
		token: true
	});
}

const getTaskReward = function(data) {
	return API.post('/wp-json/wp/v2/task/reward', data, {
		token: true
	});
}

const getTreasureReward = function(data) {
	return API.post('/wp-json/wp/v2/task/treasure-reward', data, {
		token: true
	});
}

const getWallet = function(data) {
	return API.get('/wp-json/wp/v2/wallet/', data, {
		token: true
	});
}

const getRechargeCredit = function(data) {
	return API.get('/wp-json/wp/v2/wallet/recharge-credit', data, {
		token: true
	});
}

const getRechargeVip = function(data) {
	return API.get('/wp-json/wp/v2/wallet/recharge-vip', data, {
		token: true
	});
}

const keyUse = function(data) {
	return API.post('/wp-json/wp/v2/wallet/key-use', data, {
		token: true
	});
}
const rechargeVipCredit = function(data) {
	return API.post('/wp-json/wp/v2/wallet/recharge-vip-credit', data, {
		token: true
	});
}

const getNote = function(data) {
	return API.get('/wp-json/wp/v2/wallet/note', data, {
		token: true
	});
}

const creatOrder = function(data) {
	return API.post('/wp-json/wp/v2/wallet/create-order', data, {
		token: true
	});
}

const deleteOrder = function(data) {
	return API.post('/wp-json/wp/v2/wallet/delete-order', data, {
		token: true
	});
}
const getOrderDetail = function(data) {
	return API.get('/wp-json/wp/v2/wallet/order-detail', data, {
		token: true
	});
}


const getWeather = function(data) {
	return API.get('/wp-json/wp/v2/weather', data);
}

const getScoremallGoods = function(data) {
	return API.get('/wp-json/wp/v2/scoremall/', data);
}

const getScoremallDetail = function(data) {
	return API.get('/wp-json/wp/v2/scoremall/detail/', data);
}

const exchangeGoods = function(data) {
	return API.post('/wp-json/wp/v2/scoremall/exchange/', data, {
		token: true
	});
}

const getExchangeRecord = function(data) {
	return API.get('/wp-json/wp/v2/scoremall/record/', data, {
		token: true
	});
}

const getExchangeaddress = function(data) {
	return API.get('/wp-json/wp/v2/scoremall/address/', data, {
		token: true
	});
}

const getSecondhandSetting = function(data) {
	return API.get('/wp-json/wp/v2/secondhand/setting/', data, {
		token: true
	});
}

const getSecondhandList = function(data) {
	return API.get('/wp-json/wp/v2/secondhand/list/', data, {
		token: true
	});
}

const changeGoodsStatus = function(data) {
	return API.post('/wp-json/wp/v2/secondhand/status/', data, {
		token: true
	});
}

const addBlacklist = function(data) {
	return API.post('/wp-json/wp/v2/content-manage/add-blacklist/', data, {
		token: true
	});
}

const getReprint = function(data) {
	return API.get('/wp-json/wp/v2/reprint/get/', data, {
		token: true
	});
}

const deletePost = function(data) {
	return API.post('/wp-json/wp/v2/content-manage/delete/', data, {
		token: true
	});
}

const commendPost = function(data) {
	return API.post('/wp-json/wp/v2/content-manage/commend/', data, {
		token: true
	});
}

const refusePost = function(data) {
	return API.post('/wp-json/wp/v2/content-manage/refuse/', data, {
		token: true
	});
}

const doReprint = function(data) {
	return API.post('/wp-json/wp/v2/reprint/', data, {
		token: true
	});
}

const addVote = function(data) {
	return API.post('/wp-json/wp/v2/bbs/vote', data, {
		token: true
	});
}

const addActivity = function(data) {
	return API.post('/wp-json/wp/v2/bbs/activity', data, {
		token: true
	});
}

const getReportType = function(data) {
	return API.get('/wp-json/wp/v2/report/get-type', data);
}

const addReport = function(data) {
	return API.post('/wp-json/wp/v2/report/add', data, {
		token: true
	});
}

const getGift = function(data) {
	return API.get('/wp-json/wp/v2/gift', data, {
		token: true
	});
}

const getMyGift = function(data) {
	return API.get('/wp-json/wp/v2/gift/my', data, {
		token: true
	});
}

const getGiftNote = function(data) {
	return API.get('/wp-json/wp/v2/gift/note', data, {
		token: true
	});
}

const sendGift = function(data) {
	return API.post('/wp-json/wp/v2/gift/send', data, {
		token: true
	});
}

const getReward = function(data) {
	return API.get('/wp-json/wp/v2/reward', data, {
		token: true
	});
}

const sendReward = function(data) {
	return API.post('/wp-json/wp/v2/reward/send', data, {
		token: true
	});
}

const getReferral = function(data) {
	return API.get('/wp-json/wp/v2/user/referral', data, {
		token: true
	});
}
//商城相关
const getShopSetting = function(data) {
	return API.get('/wp-json/wp/v2/shop/setting', data, {
		token: true
	});
}

const getGoodsList = function(data) {
	return API.get('/wp-json/wp/v2/shop/list', data);
}

const getGoodsRecommend = function(data) {
	return API.get('/wp-json/wp/v2/shop/recommend', data);
}

const getGoodsDetail = function(data) {
	return API.get('/wp-json/wp/v2/shop/detail', data, {
		token: true
	});
}
const addCart = function(data) {
	return API.post('/wp-json/wp/v2/shop/add-cart', data, {
		token: true
	});
}

const getCart = function(data) {
	return API.get('/wp-json/wp/v2/shop/get-cart', data, {
		token: true
	});
}

const updateCart = function(data) {
	return API.post('/wp-json/wp/v2/shop/update-cart', data, {
		token: true
	});
}

const delCart = function(data) {
	return API.post('/wp-json/wp/v2/shop/del-cart', data, {
		token: true
	});
}

const getAddress = function(data) {
	return API.get('/wp-json/wp/v2/shop/address', data, {
		token: true
	});
}

const addAddress = function(data) {
	return API.post('/wp-json/wp/v2/shop/add-address', data, {
		token: true
	});
}




const creatGoodsOrder = function(data) {
	return API.post('/wp-json/wp/v2/shop/create-order', data, {
		token: true
	});
}

const getGoodsOrder = function(data) {
	return API.get('/wp-json/wp/v2/shop/get-order', data, {
		token: true
	});
}

const updateGoodsOrder = function(data) {
	return API.post('/wp-json/wp/v2/shop/update-order', data, {
		token: true
	});
}

const deleteGoodsOrder = function(data) {
	return API.post('/wp-json/wp/v2/shop/delete-order', data, {
		token: true
	});
}

const goodsOrderDetail = function(data) {
	return API.get('/wp-json/wp/v2/shop/order-detail', data, {
		token: true
	});
}

const payAlipayGoods= function(data) {
	return API.post('/wp-json/wp/v2/payment/alipay/goods', data, {
		token: true
	});
}

const payAlipayGoodsH5= function(data) {
	return API.post('/wp-json/wp/v2/payment/alipay-h5/goods', data, {
		token: true
	});
}

const payWechatPayGoods= function(data) {
	return API.post('/wp-json/wp/v2/payment/wechatpay/goods', data, {
		token: true
	});
}

const payAlipay= function(data) {
	return API.post('/wp-json/wp/v2/payment/alipay/', data, {
		token: true
	});
}

const payAlipayH5= function(data) {
	return API.post('/wp-json/wp/v2/payment/alipay-h5', data, {
		token: true
	});
}

const payWechatPay= function(data) {
	return API.post('/wp-json/wp/v2/payment/wechatpay/', data, {
		token: true
	});
}

const addOrderComment= function(data) {
	return API.post('/wp-json/wp/v2/comments/add-order-comment', data, {
		token: true
	});
}

const getOrderComment= function(data) {
	return API.get('/wp-json/wp/v2/comments/order-comment', data, {
		token: true
	});
}

const getExpress= function(data) {
	return API.get('/wp-json/wp/v2/shop/express', data, {
		token: true
	});
}

const getSearchSetting= function(data) {
	return API.get('/wp-json/wp/v2/search/setting', data);
}

const getSearchHistory= function(data) {
	return API.get('/wp-json/wp/v2/search/history', data, {
		token: true
	});
}

const deleteSearchHistory= function(data) {
	return API.post('/wp-json/wp/v2/search/delete', data, {
		token: true
	});
}

const doSearch= function(data) {
	return API.post('/wp-json/wp/v2/search', data, {
		token: true
	});
}

API.getCitys = getCitys
API.getWechat = getWechat
API.getWechatMp = getWechatMp
API.getWechatUrl = getWechatUrl
API.getQQ = getQQ
API.getWeibo = getWeibo
API.palySmsVoice = palySmsVoice
API.getSiteInfo = getSiteInfo
API.getSetting = getSetting
API.getEdition = getEdition
API.getLastestEdition = getLastestEdition
API.getCode = getCode
API.getPhone = getPhone
API.getStickyPosts = getStickyPosts
API.getPostsList = getPostsList
API.getMyPosts = getMyPosts
API.getDiaochangList = getDiaochangList
API.getDianpuList = getDianpuList
API.getPostsbyID = getPostsbyID
API.getEditPosts = getEditPosts
API.getPagesList = getPagesList
API.getPageByID = getPageByID
API.getCategories = getCategories
API.getCategoryByID = getCategoryByID
API.getBbsPost = getBbsPost
API.getBbsLike = getBbsLike
API.getBbsList = getBbsList
API.getBbsPassword = getBbsPassword
API.pageNoPower = pageNoPower
API.getTags = getTags
API.getTagByID = getTagByID
API.getRandPosts = getRandPosts
API.getRelatePosts = getRelatePosts
API.getMostViewsPosts = getMostViewsPosts
API.getMostFavPosts = getMostFavPosts
API.getMostLikePosts = getMostLikePosts
API.getMostCommentPosts = getMostCommentPosts
API.getRecentCommentPosts = getRecentCommentPosts
API.updateViewPosts = updateViewPosts
API.getComments = getComments
API.getReplys = getReplys
API.getProfile = API.guard(getProfile)
API.fav = API.guard(fav)
API.getCollectPosts = API.guard(getCollectPosts)
API.like = API.guard(like)
API.getLikePosts = API.guard(getLikePosts)
API.getCommentsPosts = API.guard(getCommentsPosts)
API.addComment = API.guard(addComment)
API.addBbsComment = API.guard(addBbsComment)
API.commentAnswer = commentAnswer
API.commentDel = commentDel
API.commentUp = commentUp
API.addBooks = API.guard(addBooks)
API.getCodeImg = getCodeImg
API.Login = Login
API.getMenu = getMenu
API.getTab = getTab
API.indexAdsense = indexAdsense
API.listAdsense = listAdsense
API.detailAdsense = detailAdsense
API.pageAdsense = pageAdsense
API.insertAdsense = insertAdsense
API.videoAdsense = videoAdsense
API.checkUser = checkUser
API.checkPass = checkPass
API.delApply = API.guard(delApply)
API.getUserById = getUserById
API.getMineInfo = getMineInfo
API.updateProfile = updateProfile
API.updateSetting = updateSetting
API.updateUserStatus = updateUserStatus
API.getAuthorInfo = getAuthorInfo
API.getNearbyUsers = getNearbyUsers
API.getNearbyDianpu = getNearbyDianpu
API.getNearbyDiaochang = getNearbyDiaochang
API.getRanking = getRanking
API.getBanner = getBanner
API.subscribe = subscribe
API.markComment = markComment
API.getVideoList = getVideoList
API.acountlogin=acountlogin
API.getPassWord=getPassWord
API.getEmailCode=getEmailCode
API.getRegistra=getRegistra
API.logoffUser=logoffUser
API.getPassInfo=getPassInfo
API.upLoadImage=upLoadImage
API.getNotices=getNotices
API.getNoticesPush=getNoticesPush
API.getNoticeDetail=getNoticeDetail
API.setNoticeStatus=setNoticeStatus
API.setNoticePushStatus=setNoticePushStatus
API.getNoticeTabs=getNoticeTabs
API.getTagsList=getTagsList
API.uploadWords=uploadWords
API.uploadBBS=uploadBBS
API.uploadVideo=uploadVideo
API.uploadVideoImg=uploadVideoImg
API.uploadVideoImg64=uploadVideoImg64
API.uploadDiaochang=uploadDiaochang
API.uploadDianpu=uploadDianpu
API.uploadPlaces=uploadPlaces
API.uploadComment=uploadComment
API.uploadImOne=uploadImOne
API.uploadImGroup=uploadImGroup
API.uploadAvatar=uploadAvatar
API.uploadAvatar64=uploadAvatar64
API.uploadSkin=uploadSkin
API.uploadFile=uploadFile
API.uploadSecondhand=uploadSecondhand
API.uploadFileUrl=API.getHost()+'/wp-json/wp/v2/upload/file'
API.getTopic=getTopic
API.getTopicSetting=getTopicSetting
API.getHotTopic=getHotTopic
API.searchTopic=searchTopic
API.getTopicDetail=getTopicDetail
API.topicLike=topicLike
API.getTopicLike=getTopicLike
API.bbsLike=bbsLike
API.searchUser=searchUser
API.searchDiaochang=searchDiaochang
API.hotDiaochang=hotDiaochang
API.hotDianpu=hotDianpu
API.publishWords=publishWords
API.publishSingle=publishSingle
API.getPassword=getPassword
API.publishVideo=publishVideo
API.publishBbs=publishBbs
API.publishDiaochang=publishDiaochang
API.publishDianpu=publishDianpu
API.publishPlaces=publishPlaces
API.publishRedbag=publishRedbag
API.publishSecondhand=publishSecondhand
API.checkDiaochang=checkDiaochang
API.checkDianpu=checkDianpu
API.checkTopic=checkTopic
API.getPay=getPay
API.getBbsPay=getBbsPay
API.doCollect=doCollect
API.getRedbag=getRedbag
API.getRedbagSetting=getRedbagSetting
API.getFollow=getFollow
API.getFollowers=getFollowers
API.getVisitors=getVisitors
API.getBlacklist=getBlacklist
API.removeBlacklist=removeBlacklist
API.getOnline=getOnline
API.getCommentLikes=getCommentLikes
API.getSign=getSign
API.addSign=API.guard(addSign)
API.daySign=API.guard(daySign)
API.taskSign=API.guard(taskSign)
API.taskContentSign=taskContentSign
API.getMessage=getMessage
API.getMessageList=getMessageList
API.sendMessage=API.guard(sendMessage)
API.deleteChatList=API.guard(deleteChatList)
API.getGroupMessage=getGroupMessage
API.getGroupMessageList=getGroupMessageList
API.sendGroupMessage=API.guard(sendGroupMessage)
API.getTaskList=getTaskList
API.getTaskReward=getTaskReward
API.getTreasureReward=getTreasureReward
API.getWallet=getWallet
API.getRechargeCredit=getRechargeCredit
API.getRechargeVip=getRechargeVip
API.keyUse=keyUse
API.rechargeVipCredit=rechargeVipCredit
API.getNote=getNote
API.creatOrder = creatOrder
API.deleteOrder = deleteOrder
API.getOrderDetail = getOrderDetail
API.getWeather=getWeather
API.getScoremallGoods=getScoremallGoods
API.getScoremallDetail=getScoremallDetail
API.exchangeGoods=exchangeGoods
API.getExchangeRecord=getExchangeRecord
API.getExchangeaddress=getExchangeaddress
API.getSecondhandSetting=getSecondhandSetting
API.getSecondhandList=getSecondhandList
API.changeGoodsStatus=changeGoodsStatus
API.addBlacklist=addBlacklist
API.getReprint=getReprint
API.doReprint=doReprint
API.deletePost=deletePost
API.commendPost=commendPost
API.refusePost=refusePost
API.addVote=addVote
API.addActivity=addActivity
API.getReportType = getReportType
API.addReport = addReport
API.getGift = getGift
API.getMyGift = getMyGift
API.getGiftNote = getGiftNote
API.sendGift = sendGift
API.getReward = getReward
API.sendReward = sendReward
API.getReferral = getReferral
API.getShopSetting = getShopSetting
API.getGoodsList = getGoodsList
API.getGoodsRecommend = getGoodsRecommend
API.getGoodsDetail = getGoodsDetail
API.addCart = addCart
API.getCart = getCart
API.updateCart = updateCart
API.delCart = delCart
API.getAddress = getAddress
API.addAddress = addAddress
API.creatGoodsOrder = creatGoodsOrder
API.getGoodsOrder = getGoodsOrder
API.updateGoodsOrder = updateGoodsOrder
API.deleteGoodsOrder = deleteGoodsOrder
API.goodsOrderDetail = goodsOrderDetail
API.payAlipayGoods = payAlipayGoods
API.payAlipayGoodsH5 = payAlipayGoodsH5
API.payWechatPayGoods = payWechatPayGoods
API.payAlipay = payAlipay
API.payAlipayH5 = payAlipayH5
API.payWechatPay = payWechatPay
API.addOrderComment = addOrderComment
API.getOrderComment = getOrderComment
API.getExpress = getExpress
API.getSearchSetting = getSearchSetting
API.getSearchHistory = getSearchHistory
API.deleteSearchHistory = deleteSearchHistory
API.doSearch = doSearch


export default API
