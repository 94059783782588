import { createApp } from 'vue'
import App from './App'
const app = createApp(App);
app.mount('#app')
//vuex
import store from "./store";
app.config.globalProperties.$store = store

//page组件
import Page from '@/components/page'
app.component('page', Page)

//api
import API from '@/api/api.js'
app.config.globalProperties.$API = API

//websocket
import ws from '@/utils/websocket.js' 
app.config.globalProperties.$ws = ws
//import 'utils/watch.js'
//let css = require('./scss.js') 

//生产环境去除所有console.log
if (uni.getSystemInfoSync().platform !== "devtools") {
//	console.log = () => {}
}