import {createStore} from 'vuex'
import {light,dark,small,normal,large,big,huge} from '@/scss.js'

//Vuex.Store 构造器选项
export default createStore({
	//存放状态
	state: {
		theme: uni.getStorageSync('theme'),
		fontSize: uni.getStorageSync('fontSize'),
		appShow: true,
		wsOpen: false,
		locationAllow: false,
		location:{
			lng:0,
			lat:0
		}
	},
	// getters, 等同于 store.getters---全局的计算属性
	getters: {
		C: state => {
			let themeData = state.theme=='dark'?dark:light
			return themeData
		},
		S: state => {
			let fontSizeData = {}
			switch(state.fontSize){
				case 'small':
					fontSizeData = small;
					break;
				case 'normal':
					fontSizeData = normal;
					break;	
				case 'large':
					fontSizeData = large;
					break;									
				case 'big':
					fontSizeData = big;
					break;									
				case 'huge':
					fontSizeData = huge;
					break;	
				default:
					fontSizeData = normal;
			}
			console.log(fontSizeData)
			return fontSizeData
		},		
	},
	// Vuex中store数据改变的唯一方法就是mutation---修改数据的方法
	mutations: {
		changeTheme(state, e) {
			state.theme = e;
		},
		changeFontSize(state, e) {
			state.fontSize = e;
		},		
		changeAppShow(state, e) {
			state.appShow = e;
		},
		changeWsOpen(state, e) {
			state.wsOpen = e;
		},
		changeLocation(state, e){
			state.location = e;
		},
		changeLocationAllow(state, e){
			state.locationAllow = e;
		}
	},
	// 全局操作的方法
	actions: {
		
	}
})

