<template>

	<!-- 总组件 -->
	<!-- #ifdef MP -->
	<view :style="'--font_sm:'+size.font_sm+';'+'--font_base:'+size.font_base+';'+'--font_lg:'+size.font_lg+';'+'--font_big:'+size.font_big+';'+'--font_hg:'+size.font_hg+';'+'--font_icon:'+size.font_icon+';'+'--my_color:'+style.my_color+';'+'--shop_color:'+style.shop_color+';'+'--font_color_black:'+style.font_color_black+';'+'--font_color_white:'+style.font_color_white+';'+'--font_color_dark:'+style.font_color_dark+';'+'--font_color_base:'+style.font_color_base+';'+'--font_color_light:'+style.font_color_light+';' +'--font_color_disabled:'+style.font_color_disabled+';'+'--font_color_link:'+style.font_color_link+';'+'--font_color_vip:'+style.font_color_vip+';'+'--bg_color:'+style.bg_color+';'+'--bg_color_light:'+style.bg_color_light+';'+'--bg_color_dark:'+style.bg_color_dark+';'+'--bg_color_black:'+style.bg_color_black+';'+'--bg_color_mask:'+style.bg_color_mask+';'+'--border_color_dark:'+style.border_color_dark+';'+'--border_color_base:'+style.border_color_base+';'+'--border_color_light:'+style.border_color_light+';'+'--filter:'+grayCss+';'">
		<!-- #endif-->
		<!-- #ifndef MP -->
		<view :style="[cssVars]">
		<!-- #endif-->

		<view class="content-warp" :style="'min-height:'+windowHeight+'px;'+customCss">
		<loading ref="loading"  :show="loading" @close="hideLoading"></loading>
		<publish v-if="isTabBar" ref= "publish" :showPublish="publishShow"  @closePublish="closePublish"></publish>
		<uv-toast ref="uToast" />
		<uv-modal  ref="myModal"   :title="title" :showConfirmButton="showConfirm" @confirm="confirm">
			<view class="slot-content">
				<rich-text :nodes="content"  :closeOnClickOverlay="true"></rich-text>
			</view>
		</uv-modal>
		<uv-back-top icon="/static/images/fish.png" :iconStyle="{height:'60rpx',width:'60rpx'}"  :scroll-top="scrollTop"></uv-back-top>
	 <slot v-if="showContent"></slot>
	 <view class="loadtext" v-else>
	 	<mp-html :content="offTip"></mp-html>
	 </view>
		 
	<!-- #ifdef H5 -->
	 <openAPP ref="openAPP" v-if="!isWeChat"></openAPP>
	 <!-- #endif -->
	 </view>
	</view>
	
</template>

<script>
import publish from './publish/publish.vue';
import loading from './loading.vue';
import openAPP from './openAPP.vue'; 
let theme = uni.getStorageSync('theme');
import {light,dark,small,normal,large,big,huge} from '@/scss.js'
export default {
	options: {
	　　styleIsolation: 'shared'
	},
	components: {
		publish,
		loading,
		openAPP
	},
	data() {
		return {
			C:this.$store.getters.C,
			S:this.$store.getters.S,			
			isTabBar:false,
			show: true,
			title: '',
			content: '',
			showConfirm: false,
			loading: true,
			scrollTop: 0,
			publishShow:false,
			showContent:true,
			offTip:'',
			theme : uni.getStorageSync('theme'),
			fontSize : uni.getStorageSync('fontSize'),
			style: light,
			size: normal,
			fontSizeBase: '',
			grayCss: '',
			gray: false,
			fontSizeData:{
				small: small,
				normal: normal,
				large: large,
				big: big,
				huge: huge
			},
			windowHeight:200,
			pageStyle: '',
			customCss: '',
			customLightCss: '',
			customDarkCss: '',
			isWeChat: false
		};
	},
	// #ifndef MP 
	computed: {

			cssVars() {
			  return {//直接使用rpx系统不会自动转换成px，需要手动转换
				"--font_sm":uni.upx2px(this.size.font_sm.replace(/rpx/g, ""))+'px',
				"--font_base":uni.upx2px(this.size.font_base.replace(/rpx/g, ""))+'px',
				"--font_lg":uni.upx2px(this.size.font_lg.replace(/rpx/g, ""))+'px',
				"--font_big":uni.upx2px(this.size.font_big.replace(/rpx/g, ""))+'px',
				"--font_hg":uni.upx2px(this.size.font_hg.replace(/rpx/g, ""))+'px',
				"--font_icon":uni.upx2px(this.size.font_icon.replace(/rpx/g, ""))+'px',
				"--my_color":this.style.my_color,
				"--shop_color":this.style.shop_color,
				"--font_color_black":this.style.font_color_black,
				"--font_color_white":this.style.font_color_white,
				"--font_color_dark":this.style.font_color_dark,
				"--font_color_base":this.style.font_color_base,
				"--font_color_light":this.style.font_color_light,
				"--font_color_disabled":this.style.font_color_disabled,
				"--font_color_link":this.style.font_color_link,
				"--font_color_vip":this.style.font_color_vip,
				"--bg_color":this.style.bg_color,
				"--bg_color_light":this.style.bg_color_light,
				"--bg_color_dark":this.style.bg_color_dark,
				"--bg_color_black":this.style.bg_color_black,
				"--bg_color_mask":this.style.bg_color_mask,
				"--border_color_dark":this.style.border_color_dark,
				"--border_color_base":this.style.border_color_base,
				"--border_color_light":this.style.border_color_light,
				"--filter":this.grayCss,
				"--img_url":this.$API.getImgHost(),
			  };
			}, 
		
		  },
		  // #endif
		watch: {
			theme(newVal, oldVal) {
				this.style = newVal=='dark'?dark:light;
			},
			fontSize(newVal, oldVal) {
				this.size = this.fontSizeData[`${newVal}`];
				this.fontSizeBase = uni.upx2px(this.size.font_base.replace(/rpx/g, ""))+'px'
			}			
		},
	mounted(options) {
		let sysInfo = uni.getSystemInfoSync();
		this.windowHeight = sysInfo.windowHeight;
		//判断是否深色模式
		this.loadTheme();

		//判断维护模式
		this.isOn() 
		
		//判断灰色模式
		
		this.isGray() 
		
		//判断访问方式
		// #ifdef H5 

		var ua = window.navigator.userAgent.toLowerCase();
		if (ua.match(/MicroMessenger/i) == 'micromessenger') {
			this.isWeChat = true
		}

		if(!this.isWeChat){//公众号内不进行判断
			if(uni.getStorageSync('isBrower')){
				this.$refs.openAPP.close()
			}else{
				this.$refs.openAPP.open()
			}			
		}else{
			let title = document.getElementsByClassName('uni-page-head__title')
			console.log(title)
			if(title[0].textContent){
				 document.getElementsByClassName('uni-page-head')[0].remove()
			}
		}

		// #endif
		// #ifndef H5 
		uni.setStorageSync('isBrower', true);
		// #endif		
		
		// 判断是否tabBar页面
		const pages = getCurrentPages();
		const lastPage = pages[pages.length - 1];
		let route = lastPage.route
		if (lastPage && (route.indexOf('pages/index/index') !== -1||route.indexOf('pages/mine/mine') !== -1||route.indexOf('pages/find/find') !== -1||route.indexOf('pages/nearby/nearby') !== -1||route.indexOf('pages/publish/jump') !== -1)) {
			this.isTabBar=true
		} else {
			this.isTabBar=false
		}
		console.log(this.isTabBar)
	},

	methods: {
		loadTheme(){
			if(!this.gray){//非灰色模式下判断深色模式
				// this.isTabBar=false;
				// this.$nextTick(()=>{
				// 	this.isTabBar=true;
				// })
				let fontSize = this.$store.state.fontSize?this.$store.state.fontSize:'normal';
				this.style = this.$store.state.theme=='dark'?dark:light;
				this.size = this.fontSizeData[`${fontSize}`];
				this.fontSizeBase = uni.upx2px(this.size.font_base.replace(/rpx/g, ""))+'px'
				uni.setNavigationBarColor({
					frontColor: this.$store.state.theme=='dark'?dark.font_color_black:light.font_color_white,
					backgroundColor: this.$store.state.theme=='dark'?dark.bg_color:light.my_color
				})
				if(this.isTabBar){
					//#ifdef MP
					uni.setTabBarStyle({
					  color: this.style.font_color_light,
					  selectedColor: this.style.font_color_dark,
					  backgroundColor: this.style.bg_color,
					  borderStyle: this.$store.state.theme=='dark'?'black':'white'
					})
					//#endif
					//#ifndef MP
					uni.setTabBarStyle({
					  color: this.style.font_color_light,
					  selectedColor: this.style.my_color,
					  backgroundColor: this.style.bg_color,
					  borderStyle: this.$store.state.theme=='dark'?'black':'white'
					})
					//#endif					
				}

					this.customCss = this.$store.state.theme=='dark'?this.customDarkCss : this.customLightCss;
			}
		},
		isOn(){ 
			this.$API.getSetting({arg:'seast_maintenance_mode_on_off,seast_mobile_custom_css,seast_mobile_custom_dark_css'}).then(res=>{
				this.customLightCss = res.seast_mobile_custom_css
				this.customDarkCss = res.seast_mobile_custom_dark_css
				this.customCss = this.$store.state.theme=='dark'? res.seast_mobile_dark_css  : res.seast_mobile_custom_css
				this.$forceUpdate()
				
				if(res.seast_maintenance_mode_on_off=='1'){//判断是否开启维护模式
					this.$API.getSetting({arg:'seast_maintenance_mode_url,seast_maintenance_mode_html,seast_maintenance_mode_h5_on_off'}).then(res=>{
						let  url = res.seast_maintenance_mode_url
						let  h5OnOff = res.seast_maintenance_mode_h5_on_off
						const urlRegExp = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
						// #ifdef H5

							if(h5OnOff=='1'){//如果当前为h5环境，判断h5是否开启维护模式
								this.showContent= false
								if(urlRegExp.test(url)){
									uni.navigateTo({
										url:'/pages/view/view?url='+url
									})
								}else{
									this.offTip= res.seast_maintenance_mode_html
								}								
							}else{
								this.showContent= true
							}
						// #endif
						// #ifndef H5
						this.showContent= false
						if(urlRegExp.test(url)){//非h5页面正常判断
							uni.navigateTo({
								url:'/pages/view/view?url='+url
							})
						}else{
							this.offTip= res.seast_maintenance_mode_html
						}
						// #endif
					})
				}
			})
		},
		isGray(){
			
			this.$API.getSetting({arg:'seast_gray_mode_on_off'}).then(res=>{
				
				if(res.seast_gray_mode_on_off=='1'){
					this.grayCss = "grayscale(100%)"
					this.gray = true
					uni.setNavigationBarColor({
						frontColor:"#000000",
						backgroundColor:"#cccccc"
					})
					if(this.isTabBar){
						uni.setTabBarStyle({
							color: "#999999",
							selectedColor: "#000000",
							backgroundColor: "#ffffff"
							
						})
					}
				}
			})			
		},
		showPublish() {
			this.$refs.publish.open()
			this.publishShow = true
		},
		closePublish() {
			let pages = getCurrentPages();
			let currentPage = pages[pages.length-1];
			currentPage.$vm.showVideo = true;
			this.publishShow = false
		},
		hidePublish() {
			this.$refs.publish.close()
			this.publishShow = false
		},
	
		// toast测试
		showToast(message, type = 'success',position = 'center') {
			this.$refs.uToast.show({
				message:message,
				type:type,
				position:position
			});
		},
		
		
		showModal(title, content,autoClose=true){
			this.$refs.myModal.open()
			this.title = title ;
			this.content = content;
			if(autoClose){
				setTimeout(() => {
								// 2秒后自动关闭
								this.$refs.myModal.close()
							}, 2000)
			}else{
				this.showConfirm = true;
			}
		},
		confirm(){
			this.$refs.myModal.close()
		},
		
		showLoading(){
			this.loading = true
		},
		hideLoading(){
			this.loading = false
		},		
		closeApp(){
			this.openShow = false
		}
		
	}
};
</script>

<style lang="scss">

	.content-warp{
		
		background-color: var(--bg_color);
		color: var(--font_color_base);
		 :deep(text) ,:deep(image) ,:deep(button) ,:deep(input) ,:deep(video) ,:deep(img) ,:deep(uv-tabs) ,:deep(uv-tags) ,:deep(uv-row) ,:deep(uv-switch) ,:deep(uv-line-progress) ,:deep(uv-radio) ,:deep(uv-scroll-list) ,:deep(uv-navbar) ,:deep(.uv-tabs) ,:deep(.uv-tags) ,:deep(.uv-row) ,:deep(.uv-switch) ,:deep(.uv-line-progress) ,:deep(.uv-radio) ,:deep(.uv-scroll-list) ,:deep(.uv-navbar) ,:deep(.logo-bg-box),:deep(.pub_page_modal) ,:deep(.btn) ,:deep(.on) ,:deep(.seast-author-bg) ,:deep(.taglist) ,:deep(.page) ,:deep(.earn) ,:deep(.font-resize) ,:deep(.uni-fab__circle) ,:deep(.navbar) ,:deep(.cate-list) ,:deep(.page-bottom) ,:deep(.seast-mark)  {
			filter: var(--filter) !important;
		}		
	}

.u-toast{
	z-index: 99999999;
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 40rpx;
	
}

</style>
