// 以下项目可以删减或更换顺序，但不能添加或更改名字
export default {
  // 普通标签的菜单项
  node: ['大小', '颜色', '斜体', '粗体', '下划线', '居中', '缩进', '上移', '下移', '删除'],
  // 可以设置的文字颜色，此项可以添加 css 颜色
  color: ['red', 'yellow', 'blue', 'green', 'gray', 'white', 'black'],
  // 图片的菜单项
  img: ['换图', '宽度', '超链接', '预览图', '禁用预览', '上移', '下移', '删除'],
  // 链接的菜单项
  link: ['更换链接', '上移', '下移', '删除'],
  // 音视频的菜单项
  media: ['封面', '循环', '自动播放', '上移', '下移', '删除'],
  // 卡片的菜单项
  card: ['上移', '下移', '删除']
}
