<template>
		
				<uv-overlay :show="show" :duration="duration"  :z-index ="999" :opacity="opacity">
					<view class="showloading">
						<!-- #ifdef APP-PLUS -->
						<image class="loadicon image" src="@/static/app-plus/loading/loading.gif" style="width: 150rpx;height: 150rpx;"></image>
						<!-- #endif -->
						<!-- #ifndef APP-PLUS -->
						<image class="loadicon image" src="@/static/images/loading2.gif" style="width: 130rpx;height: 140rpx;"></image>
						<!-- #endif -->									
						<view class="loadtext">
							<text class="text">加载中，请稍后</text>
						</view>
					</view>
				</uv-overlay>

</template>

<script>
	export default {
		name:"loading",
		props:{
			show: {
				type:Boolean,
				default:true
			},
			duration: {
				type:Number,
				default:0
			},
			opacity: {
				type:Number,
				default:0.5			
			}
			
		},
		data() {
			return {
				
			};
		},
		mounted() {
			setTimeout(()=>{
				if(this.show===true){
						this.$emit("close")	
				}
			},3000)
		}
		
	}
</script>

<style lang="scss" scoped>
	.showloading {
		
		position: fixed;
		text-align: center;
		transition: .3s;
		width: 100%;
		height: 100%;
		z-index: 100000;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-flow: column;
	
		.load {
			opacity: 1;
			transition: .3s;
			top: 0;
		}
	
		.loadtext {
			display: flex;
			align-items: center;
			justify-content: center;
	
			.text {
				font-size: var(--font_sm);
				opacity: .5;
				color:#FFFFFF
			}
		}
		.image{
			
					
		}
		.loadicon {
	
			position: relative;
			z-index: 10;
			
	
			
		}
	}
</style>
