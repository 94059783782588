export default {
    'uvicon-level': 'e693',
    'uvicon-column-line': 'e68e',
    'uvicon-checkbox-mark': 'e807',
    'uvicon-folder': 'e7f5',
    'uvicon-movie': 'e7f6',
    'uvicon-star-fill': 'e669',
    'uvicon-star': 'e65f',
    'uvicon-phone-fill': 'e64f',
    'uvicon-phone': 'e622',
    'uvicon-apple-fill': 'e881',
    'uvicon-chrome-circle-fill': 'e885',
    'uvicon-backspace': 'e67b',
    'uvicon-attach': 'e632',
    'uvicon-cut': 'e948',
    'uvicon-empty-car': 'e602',
    'uvicon-empty-coupon': 'e682',
    'uvicon-empty-address': 'e646',
    'uvicon-empty-favor': 'e67c',
    'uvicon-empty-permission': 'e686',
    'uvicon-empty-news': 'e687',
    'uvicon-empty-search': 'e664',
    'uvicon-github-circle-fill': 'e887',
    'uvicon-rmb': 'e608',
    'uvicon-person-delete-fill': 'e66a',
    'uvicon-reload': 'e788',
    'uvicon-order': 'e68f',
    'uvicon-server-man': 'e6bc',
    'uvicon-search': 'e62a',
    'uvicon-fingerprint': 'e955',
    'uvicon-more-dot-fill': 'e630',
    'uvicon-scan': 'e662',
    'uvicon-share-square': 'e60b',
    'uvicon-map': 'e61d',
    'uvicon-map-fill': 'e64e',
    'uvicon-tags': 'e629',
    'uvicon-tags-fill': 'e651',
    'uvicon-bookmark-fill': 'e63b',
    'uvicon-bookmark': 'e60a',
    'uvicon-eye': 'e613',
    'uvicon-eye-fill': 'e641',
    'uvicon-mic': 'e64a',
    'uvicon-mic-off': 'e649',
    'uvicon-calendar': 'e66e',
    'uvicon-calendar-fill': 'e634',
    'uvicon-trash': 'e623',
    'uvicon-trash-fill': 'e658',
    'uvicon-play-left': 'e66d',
    'uvicon-play-right': 'e610',
    'uvicon-minus': 'e618',
    'uvicon-plus': 'e62d',
    'uvicon-info': 'e653',
    'uvicon-info-circle': 'e7d2',
    'uvicon-info-circle-fill': 'e64b',
    'uvicon-question': 'e715',
    'uvicon-error': 'e6d3',
    'uvicon-close': 'e685',
    'uvicon-checkmark': 'e6a8',
    'uvicon-android-circle-fill': 'e67e',
    'uvicon-android-fill': 'e67d',
    'uvicon-ie': 'e87b',
    'uvicon-IE-circle-fill': 'e889',
    'uvicon-google': 'e87a',
    'uvicon-google-circle-fill': 'e88a',
    'uvicon-setting-fill': 'e872',
    'uvicon-setting': 'e61f',
    'uvicon-minus-square-fill': 'e855',
    'uvicon-plus-square-fill': 'e856',
    'uvicon-heart': 'e7df',
    'uvicon-heart-fill': 'e851',
    'uvicon-camera': 'e7d7',
    'uvicon-camera-fill': 'e870',
    'uvicon-more-circle': 'e63e',
    'uvicon-more-circle-fill': 'e645',
    'uvicon-chat': 'e620',
    'uvicon-chat-fill': 'e61e',
    'uvicon-bag-fill': 'e617',
    'uvicon-bag': 'e619',
    'uvicon-error-circle-fill': 'e62c',
    'uvicon-error-circle': 'e624',
    'uvicon-close-circle': 'e63f',
    'uvicon-close-circle-fill': 'e637',
    'uvicon-checkmark-circle': 'e63d',
    'uvicon-checkmark-circle-fill': 'e635',
    'uvicon-question-circle-fill': 'e666',
    'uvicon-question-circle': 'e625',
    'uvicon-share': 'e631',
    'uvicon-share-fill': 'e65e',
    'uvicon-shopping-cart': 'e621',
    'uvicon-shopping-cart-fill': 'e65d',
    'uvicon-bell': 'e609',
    'uvicon-bell-fill': 'e640',
    'uvicon-list': 'e650',
    'uvicon-list-dot': 'e616',
    'uvicon-zhihu': 'e6ba',
    'uvicon-zhihu-circle-fill': 'e709',
    'uvicon-zhifubao': 'e6b9',
    'uvicon-zhifubao-circle-fill': 'e6b8',
    'uvicon-weixin-circle-fill': 'e6b1',
    'uvicon-weixin-fill': 'e6b2',
    'uvicon-twitter-circle-fill': 'e6ab',
    'uvicon-twitter': 'e6aa',
    'uvicon-taobao-circle-fill': 'e6a7',
    'uvicon-taobao': 'e6a6',
    'uvicon-weibo-circle-fill': 'e6a5',
    'uvicon-weibo': 'e6a4',
    'uvicon-qq-fill': 'e6a1',
    'uvicon-qq-circle-fill': 'e6a0',
    'uvicon-moments-circel-fill': 'e69a',
    'uvicon-moments': 'e69b',
    'uvicon-qzone': 'e695',
    'uvicon-qzone-circle-fill': 'e696',
    'uvicon-baidu-circle-fill': 'e680',
    'uvicon-baidu': 'e681',
    'uvicon-facebook-circle-fill': 'e68a',
    'uvicon-facebook': 'e689',
    'uvicon-car': 'e60c',
    'uvicon-car-fill': 'e636',
    'uvicon-warning-fill': 'e64d',
    'uvicon-warning': 'e694',
    'uvicon-clock-fill': 'e638',
    'uvicon-clock': 'e60f',
    'uvicon-edit-pen': 'e612',
    'uvicon-edit-pen-fill': 'e66b',
    'uvicon-email': 'e611',
    'uvicon-email-fill': 'e642',
    'uvicon-minus-circle': 'e61b',
    'uvicon-minus-circle-fill': 'e652',
    'uvicon-plus-circle': 'e62e',
    'uvicon-plus-circle-fill': 'e661',
    'uvicon-file-text': 'e663',
    'uvicon-file-text-fill': 'e665',
    'uvicon-pushpin': 'e7e3',
    'uvicon-pushpin-fill': 'e86e',
    'uvicon-grid': 'e673',
    'uvicon-grid-fill': 'e678',
    'uvicon-play-circle': 'e647',
    'uvicon-play-circle-fill': 'e655',
    'uvicon-pause-circle-fill': 'e654',
    'uvicon-pause': 'e8fa',
    'uvicon-pause-circle': 'e643',
    'uvicon-eye-off': 'e648',
    'uvicon-eye-off-outline': 'e62b',
    'uvicon-gift-fill': 'e65c',
    'uvicon-gift': 'e65b',
    'uvicon-rmb-circle-fill': 'e657',
    'uvicon-rmb-circle': 'e677',
    'uvicon-kefu-ermai': 'e656',
    'uvicon-server-fill': 'e751',
    'uvicon-coupon-fill': 'e8c4',
    'uvicon-coupon': 'e8ae',
    'uvicon-integral': 'e704',
    'uvicon-integral-fill': 'e703',
    'uvicon-home-fill': 'e964',
    'uvicon-home': 'e965',
    'uvicon-hourglass-half-fill': 'e966',
    'uvicon-hourglass': 'e967',
    'uvicon-account': 'e628',
    'uvicon-plus-people-fill': 'e626',
    'uvicon-minus-people-fill': 'e615',
    'uvicon-account-fill': 'e614',
    'uvicon-thumb-down-fill': 'e726',
    'uvicon-thumb-down': 'e727',
    'uvicon-thumb-up': 'e733',
    'uvicon-thumb-up-fill': 'e72f',
    'uvicon-lock-fill': 'e979',
    'uvicon-lock-open': 'e973',
    'uvicon-lock-opened-fill': 'e974',
    'uvicon-lock': 'e97a',
    'uvicon-red-packet-fill': 'e690',
    'uvicon-photo-fill': 'e98b',
    'uvicon-photo': 'e98d',
    'uvicon-volume-off-fill': 'e659',
    'uvicon-volume-off': 'e644',
    'uvicon-volume-fill': 'e670',
    'uvicon-volume': 'e633',
    'uvicon-red-packet': 'e691',
    'uvicon-download': 'e63c',
    'uvicon-arrow-up-fill': 'e6b0',
    'uvicon-arrow-down-fill': 'e600',
    'uvicon-play-left-fill': 'e675',
    'uvicon-play-right-fill': 'e676',
    'uvicon-rewind-left-fill': 'e679',
    'uvicon-rewind-right-fill': 'e67a',
    'uvicon-arrow-downward': 'e604',
    'uvicon-arrow-leftward': 'e601',
    'uvicon-arrow-rightward': 'e603',
    'uvicon-arrow-upward': 'e607',
    'uvicon-arrow-down': 'e60d',
    'uvicon-arrow-right': 'e605',
    'uvicon-arrow-left': 'e60e',
    'uvicon-arrow-up': 'e606',
    'uvicon-skip-back-left': 'e674',
    'uvicon-skip-forward-right': 'e672',
    'uvicon-rewind-right': 'e66f',
    'uvicon-rewind-left': 'e671',
    'uvicon-arrow-right-double': 'e68d',
    'uvicon-arrow-left-double': 'e68c',
    'uvicon-wifi-off': 'e668',
    'uvicon-wifi': 'e667',
    'uvicon-empty-data': 'e62f',
    'uvicon-empty-history': 'e684',
    'uvicon-empty-list': 'e68b',
    'uvicon-empty-page': 'e627',
    'uvicon-empty-order': 'e639',
    'uvicon-man': 'e697',
    'uvicon-woman': 'e69c',
    'uvicon-man-add': 'e61c',
    'uvicon-man-add-fill': 'e64c',
    'uvicon-man-delete': 'e61a',
    'uvicon-man-delete-fill': 'e66a',
    'uvicon-zh': 'e70a',
    'uvicon-en': 'e692'
}
