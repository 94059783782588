import BASE from '@/api/base.js'
import API from '@/api/api.js'
import store from '@/store/index.js'
import TransformCoordinate from '@/components/ms-openMap/transformCoordinate.js'
import permission from '@/utils/permission.js'
//是否已经连接上ws
let isOpenSocket = false
//心跳间隔，单位毫秒
let heartBeatDelay = 30000
let heartBeatInterval = null
//心跳时发送的消息文本
let heartBeatText = "PING"
//最大重连次数
let reconnectTimes = 10
let reconnectInterval = null
//重连间隔，单位毫秒
let reconnectDelay = 3000

let wsUrl = "wss://www.lindiaoren.com/wss"
//let wsUrl = "ws://127.0.0.1:8000"
let socketTask = null

//这个参数是防止重连失败之后onClose方法会重复执行reconnect方法，导致重连定时器出问题
//连接并打开之后可重连，且只执行重连方法一次
let canReconnect = false
let userInfo = {}

//封装的对象，最后以模块化向外暴露，
//init方法 初始化socketTask对象
//completeClose方法 完全将socketTask关闭（不重连）
//其他关于socketTask的方法与uniapp的socketTask api一致
let ws = {
	socketTask: null,
	init,
	completeClose,
	send,
	isOpen,
	reconnect
}
function isOpen(){
	return isOpenSocket;
}
function init(path='') {
	userInfo = BASE.getUser()
	if(userInfo){
		
		// if(ws.socketTask&&!path){
		// 	//更新用户状态
		// 	API.updateUserStatus({lat:userInfo.lat,lng:userInfo.lng,online:1}).then(res=>{
		// 		console.log(res)
		// 	}, err => {
		// 			console.log(err)
		// 	})	
		// 	return false; 	
		// }
		let url = path? wsUrl+'/?'+path : wsUrl

		socketTask = uni.connectSocket({
			url: url,
			complete: ()=> {}
		})
		//let prarms = qs.parse(path)
		socketTask.onOpen(async (res) => {
			console.log("ws连接成功")
			clearInterval(heartBeatInterval)
			clearInterval(reconnectInterval)
			store.commit('changeWsOpen', true);
			isOpenSocket = true
			canReconnect = true

			if(!path){
				if(store.state.locationAllow){
					uni.getLocation({
						success: res => {
							let lngLat = TransformCoordinate.wgs84togcj02(res.longitude,res.latitude)
							userInfo.lat = lngLat[1]
							userInfo.lng = lngLat[0]
							console.log('websocket地址获取成功', res);
						
						},
						fail: () => {
							console.log('地理位置获取失败');
						}
					});					
				}else{
					userInfo.lat = 0
					userInfo.lng = 0
				}

				setTimeout(()=>{
					console.log(userInfo.lat)
					//更新用户状态
					API.updateUserStatus({lat:userInfo.lat,lng:userInfo.lng,online:1}).then(res=>{
						console.log(res)
					}, err => {
							console.log(err)
					})	
								
				},2000)
				
			
			
				let data = {
					type: "setname",
					id: userInfo.userId,
					name: userInfo.nickName
				}
				//发送当前用户信息
				send(JSON.stringify(data))
			}
			//开启心跳机制
			heartBeat()
		})
		socketTask.onMessage((res) => {
			//自定义处理onMessage方法
			
			let info = JSON.parse(res.data)
			if(info.type == 'chat'){
				// #ifndef APP-PLUS
				API.palySmsVoice()
				// #endif
				// #ifdef APP-PLUS
				let newMessage = info.message
				console.log(newMessage)	
				let pushContent = newMessage.msg.content.text
				if(newMessage.msg.type=="img"){ 
					pushContent = '[图片]'
				}else{
					if(newMessage.msg.type=="voice") pushContent = '[语音]'
					else pushContent = newMessage.msg.oldContent
				}
				if(!store.state.appShow){//app后台运行才会推送聊天信息
					//推送
					let payload = {url:"/pagesB/chat/chat?id="+newMessage.msg.userinfo.uid}
					API.sendPushMessage(newMessage.msg.userinfo.username,pushContent,payload)
				}else{
					API.palySmsVoice()
				}

			

				// #endif
			}
			
			if(info.type == 'leave'){
				isOpenSocket=false;
				store.commit('changeWsOpen', false);
			}

			console.log(res.data)
		})
		socketTask.onError(function(e) {  
			
			isOpenSocket=false;
		        console.log("异常：" + JSON.stringify(e));  
		        // console.log(e.data);    
		});  
		socketTask.onClose(() => {
				//更新用户状态
				API.updateUserStatus({user_id:userInfo.userId,online:0}).then(res=>{
					console.log(res)
				}, err => {
						console.log(err)
				})				
			if(isOpenSocket){
				console.log("ws与服务器断开")
			
			}else{
				console.log("连接失败")
			}
			store.commit('changeWsOpen', false);
			isOpenSocket = false
			if(canReconnect){
				reconnect()
				canReconnect = false 
			}
		})
	}
	ws.socketTask = socketTask
	
}

function heartBeat() {
	
	heartBeatInterval = setInterval(() => {
		//console.log(heartBeatText)
		send(heartBeatText);
		
		// #ifdef APP-PLUS
		setTimeout(()=>{
				API.getNoticesPush().then(res => {
					//console.log(res)
					if(res.code){
						let data = res.data
						for(var i=0;i< data.length;i++){
							let payload = {url:data[i].url}
							API.setNoticePushStatus({id:data[i].id}).then(res => {
									console.log(res.msg)								
							})								
							API.sendPushMessage(data[i].title,data[i].content,payload)
																
						}
					}else{
						
					}
				})					
				.catch(err => {
					console.log(err)
				})	
		},2000)			
		// #endif
	}, heartBeatDelay)
}

function send(value) {
	ws.socketTask.send({
		data: value,
		async success() {
			//console.log("消息发送成功")
		}
	});
}

function reconnect() {
	//停止发送心跳
	clearInterval(heartBeatInterval)
	//如果不是人为关闭的话，进行重连
	if (!isOpenSocket) {
		let count = 0;
		reconnectInterval = setInterval(() => {
			console.log("正在尝试重连")
			init();
			count++
			//重连一定次数后就不再重连
			if(count >= reconnectTimes){
				clearInterval(reconnectInterval)
				console.log("网络异常或服务器错误")
			}
		}, reconnectDelay)
	}
}
function completeClose(userId){
	if(userId){
		//更新用户状态
		API.updateUserStatus({user_id:userId,online:0}).then(res=>{
			console.log(res)
		}, err => {
				console.log(err)
		})			
	}
			
	//先将心跳与重连的定时器清除
	clearInterval(heartBeatInterval)
	clearInterval(reconnectInterval)
	canReconnect = false
	console.log('主动关闭')
	ws.socketTask.close()
}

export default ws
