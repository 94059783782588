<template>

	<uv-popup ref="publish" mode="center" @close="close" round="20" :closeable="closeable" :closeOnClickOverlay="closeable" :safeAreaInsetTop="false" @change="changeStatus" overlayOpacity="0.8" animation="slide-bottom" :customStyle="{overflow:'visible',height:windowHeight,top: navigationBarTop+'px'}" :bgColor="C.bg_color" :overlayStyle="{backgroundColor:C.bg_color_mask}">

		<view class="pub_page_modal" :style="{height:windowHeight}">
			<view class="ad">
				<view class="fttime clear" id="show_datebox">
					<view class="fttime_yl">
						<view class="fttime_xq">
							<text>星期{{Ddate.week}}</text>
							<em>　</em>
							<text>{{Ddate.year}}年{{Ddate.month}}月</text>
						</view>
						<view class="fttime_rq">{{Ddate.day}}</view>						
					</view>

					<view class="fttime_nl"><text>{{Ddate.cmonth}}</text><text>{{Ddate.cday}}</text></view>
				</view>
			</view>
			<view class="tip" v-if="tip">
				<mp-html :content="tip"></mp-html>
			</view>
			<view class="fabu-btn-con">
				<view class="fabu-btn-wrap">
					<uv-grid :showBorder="false">
						<uv-grid-item :customStyle="{width:'150rpx'}" class="menu_item"
							v-for="(item,index) in publishType" :key="index" @click="fabu(item.type,item.link)">
							<view :style="{background: item.icoBg}" class="menu_item_icon"><text class="seast-icon"
									:class="item.ico"></text></view>
							<text class="text" :style="item.color?{color: item.color}:{color: C.font_color_dark}">{{item.name}}</text>
						</uv-grid-item>

					</uv-grid>
				</view>

			</view>
		</view>
		<!-- #ifndef MP-TOUTIAO -->
				<view class="close-modal" @click="close"></view>
		<!-- #endif -->
		<!-- #ifdef MP-TOUTIAO -->
				<view class="close-modal"></view>
		<!-- #endif -->		
	</uv-popup>

</template>

<script>
	import Lunar from '@/utils/lunar.js'
	export default {
		name: "publish",
		props: {
			showPublish: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				C:this.$store.getters.C,
				S:this.$store.getters.S,
				publishType: [],
				tip:'',
				Ddate:{
					week:'',
					year:'',
					month:'',
					day:'',
					cmonth:'',
					cday:''
				},
				windowHeight: "200px",
				navigationBarTop:0,
				// #ifdef MP-TOUTIAO
				closeable:false
				// #endif
				// #ifndef MP-TOUTIAO
				closeable:true
				// #endif				
			}
		},
		mounted: function() {
			let h = 0
			uni.getSystemInfo({
				success: (res) => {
					console.log(res)
					let navigationBarHeight = 0
					//计算导航栏高度
					navigationBarHeight = res.screenHeight - res.windowHeight - res.windowBottom;
					//#ifdef H5
						h = 120
					//#endif
					//#ifdef APP-PLUS
					navigationBarHeight -= 50
					h = navigationBarHeight === 0?(60+44+res.statusBarHeight) : 60 ;//判断是否有头部导航
					//#endif			
					//#ifdef MP
						navigationBarHeight = 0
						//#ifdef MP-TOUTIAO
						navigationBarHeight = 40
						//#endif
						h = 120
					//#endif					  
					this.windowHeight = (res.windowHeight + res.windowTop-h) + "px";
					if(navigationBarHeight === 0)  this.navigationBarTop = 24
				}
			})
			// 获取当前页面栈
			const pages = getCurrentPages();
			// 获取最后一个页面对象
			const lastPage = pages[pages.length - 1];
			// 判断最后一个页面的路由属性是否包含"tabbar"关键字
			let route = lastPage.route
			if (lastPage && (route.indexOf('pages/index/index') !== -1||route.indexOf('pages/mine/mine') !== -1||route.indexOf('pages/find/find') !== -1||route.indexOf('pages/publish/jump') !== -1)) {
			    
				
			} else {
		
			}
			this.$API.getSetting({arg:'seast_publish_add,seast_mobile_publish_type_custom'}).then(res=>{
				if(res){
					this.publishType = res.seast_publish_add
					this.tip = res.seast_mobile_publish_type_custom
				}
			})
		
			this.setDate()
		},
		methods: {
			changeStatus(e){
				if(!e.show){
					this.$emit("closePublish")
				}else{
					this.$emit("showVideos")
				}
			},
			close: function() {
				// #ifdef MP
				this.$emit("closePublish")
				// #endif
				this.$refs.publish.close()				
			},
			open: function() {
				this.$refs.publish.open()
			},
			fabu: function(type,link) {
				// #ifndef MP
				this.close();
				// #endif
				if(link){
					uni.navigateTo({
						url: link
					})					
				}else{
					if (type == "bbs") {
						uni.navigateTo({
							url: "/pages/bbs/bbs-like?type=publish"
						})
					} else {
						uni.navigateTo({
							url: "/pagesA/publish/" + type
						})
					}					
				}


			},

			sun_timesadd0:function(obj){
				if(obj<10){
					return "0" +""+ obj;
				}else{
					return obj;
				}
			},
			getFullYear:function(d) {
			   var  yr = d.getYear();
			    if (yr < 1000) yr += 1900;
			    return yr
			},

			setDate(){
				var D = new Date();
				var yy = D.getFullYear();
				var mm = D.getMonth() + 1;
				var dd = D.getDate();
				var ww = D.getDay();
				var ss = parseInt(D.getTime() / 1000);			
				var sWeek = new Array("日", "一", "二", "三", "四", "五", "六");
				var dNow = new Date();
				this.Ddate.week = sWeek[dNow.getDay()] 
				this.Ddate.year = this.getFullYear(dNow)
				this.Ddate.month = this.sun_timesadd0(dNow.getMonth() + 1)
				this.Ddate.day  = this.sun_timesadd0(dNow.getDate()) 
				let lunArr = Lunar.toLunar(yy, mm, dd)
				this.Ddate.cmonth = lunArr[5]
				this.Ddate.cday = lunArr[6]
			}
		}
	}
</script>

<style lang="scss" scoped>
	.pub_page_modal {
		height: 100%;
		background-color: var(--bg_color);
		width: 680rpx;
		z-index: 1000000000;
		border-radius: 20rpx;
		
	}

	.show {
		opacity: 1;
		z-index: 1000000;
	}

	.hide,
	.show {
		// transition: all .4s;
	}

	.hide {
		opacity: 0;
		z-index: -999999;
	}

	.btn {
		text-align: left;
	}

	.fabu-btn-con {
		bottom: 0;
		left: 0;
		padding-bottom: 40rpx;
		position: absolute;
		width: 100%;
		z-index: 1000;

	}

	.fabu-btn-wrap {
		margin: auto;
		width: 600rpx;
		z-index: 1000;

	}

	.text {
		line-height: 60rpx;
		font-size: var(--font_lg);
		white-space: nowrap;
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		text-align: center;
	}

	.menu_item {
		width: 150rpx;
		text-align: center;
		margin-bottom: 10rpx;
		margin-top: 10rpx;

	}

	.menu_item_icon {
		width: 100rpx;
		height: 100rpx;
		line-height: 100rpx;
		margin: 0 auto;
		background-color: #f48924;
		border-radius: 20rpx;
		text-align: center;
	}

	.seast-icon {
		font-size: 50rpx;
		color: #FFFFFF;
	}


	.close-modal {
		    line-height: initial;
		    color: var(--bg_color);
		    width: 0;
		    border-width: 20px 20px 0;
		    border-style: solid;
		    border-color: var(--bg_color) transparent transparent;
			margin:0 auto;
			
			/* #ifndef MP */
			margin-bottom: -20px;
			/* #endif */
			/* #ifdef MP */
			margin-bottom: -54px;
			/* #endif */
			
	}


	.cha {
		background: #fecd33;
		border-radius: 4rpx;
		height: 30rpx;
		position: relative;
		width: 5rpx;
	}

	.cha1 {
		left: 25rpx;
		top: 15rpx;
		transform: rotate(50deg);
	}

	.cha2 {
		left: 25rpx;
		top: 18rpx;
		transform: rotate(-50deg);
	}

	.fttime {
		padding:40rpx 75rpx;
		font-size: var(--font_base);
		position: relative;
		display: flex;
		justify-content: space-between;
	}
	
	.fttime_yl {
		display: flex;
		flex-direction: row;
	}
	
	.fttime_rq {
		height: 100rpx;
		line-height: 100rpx;
		font-size: 116rpx;
		font-family: 'Helvetica';
		float: left;
		color: #777 !important;
		margin-right: 40rpx;
	}

	.fttime_xq {
		height: 100rpx;
		margin: 0 20rpx;
		display: flex;
		flex-direction: column;
	}

	.fttime_xq text {
		text-align: center;
		
		font-size: var(--font_base);
		line-height: 50rpx
	}

	.fttime_nl {
		width: 100rpx;
		height: 100rpx;
		display: flex;
		flex-direction: column;
		border-radius: 3px;
		
		background: #46c47c !important;
		color: #fff !important;
		margin-right: 40rpx;
	}

	.fttime_nl text:first-child {
		height: 38rpx;
		line-height: 44rpx;
		text-align: center;
		font-size: var(--font_lg);
		margin: 5rpx;
	}

	.fttime_nl text:last-child {
		height: 44rpx;
		line-height: 48rpx;
		margin: 5rpx;
		font-size: var(--font_base);
		text-align: center;
		border-radius: 3px;
		background-color: var(--bg_color);
		color: #999 !important;
	}
	.tip{
		font-size: var(--font_base);
		margin: 0 20rpx;
	}
</style>